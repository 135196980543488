import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme/defaultTheme";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/de";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import HomePage from "./components/_Pages/HomePage/HomePage";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import BaseRoutes from "./routes/BaseRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TechStatusProvider from "./provider/TechStatusProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DutyNotificationSnackbar } from "./components/Snackbar/DutyNotificationSnackbar";
import { CaseRefreshNotificationSnackbar } from "./components/Snackbar/CaseRefreshNotificationSnackbar";
import { CustomSnackbar } from "./components/Snackbar/CustomSnackBar";
import { SystemNoteNotificationSnackbar } from "./components/Snackbar/SystemNoteNotificationSnackbar";
import { NewMessageNotificationSnackbar } from "./components/Snackbar/NewMessageNotificationSnackbar";

function App() {
  //configure moment for the whole project
  moment.fn.toJSON = function () {
    return this.format();
  };
  moment.locale("de");

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        staleTime: 1000
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              maxSnack={1}
              Components={{
                custom: CustomSnackbar,
                dutyNotification: DutyNotificationSnackbar,
                caseRefreshNotification: CaseRefreshNotificationSnackbar,
                systemNoteNotification: SystemNoteNotificationSnackbar,
                newMessageNotification: NewMessageNotificationSnackbar,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TechStatusProvider>
                  <ScrollToTop>
                    <Routes>
                      <Route
                        path={"/services/*"}
                        element={
                          <RequireAuth>
                            <BaseRoutes />
                          </RequireAuth>
                        }
                      />
                      <Route path={"/*"} element={<PublicRoutes />} />
                      <Route
                        path={"/"}
                        element={
                          <RequireAuth>
                            <HomePage />
                          </RequireAuth>
                        }
                      />
                    </Routes>
                  </ScrollToTop>
                </TechStatusProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
