import createCaseFormStyle from "./createCaseFormStyle";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import useForm, { UseFormProps } from "../../hooks/useForm";
import ValidatorSelect, { ValidatorSelectProps } from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import { convertToFloat, formValue } from "../../services/formServiceFunctions";
import { Checkbox, FormControlLabel, InputLabel, Stack, TextField } from "@mui/material";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ButtonLoading from "../Button/ButtonLoading";
import _ from "lodash";
import { getCaseLink, getProductData } from "../../services/Product/ProductService";

import { useSnackbar } from "notistack";

import {
  atLeastOneFieldRequiredDefault,
  caseReferenceNumberIsUnique,
  emailDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  matchRegexp,
  requiredFieldDefault,
} from "../../services/validationRules";
import { translate } from "../../services/Translations/translatorService";
import ApiClient from "../../services/ApiClient";
import PageHeadline from "../PageHeadline/PageHeadline";
import ContentBox from "../ContentBox/ContentBox";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { apiGet } from "../../services/Api/apiCall";
import { useMutation } from "@tanstack/react-query";
import { createResource, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ValidatorNumberField from "../Validator/ValidatorNumberField";
import InfoBox from "../InfoBox/InfoBox";
import { ValidatorType } from "../../types/ValidatorType";

// @ts-ignore
const useStyles = makeStyles(createCaseFormStyle);

const CreateCase = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [customerStatus, setCustomerStatus] = useState("notChecked");
  const [hasNoEmail, setHasNoEmail] = useState(false);
  const currentUser = useCurrentUser();
  const isCallcenterUser = userHasRole(currentUser, Roles.callcenter);
  const createMutation = useMutation(createResource);
  const updateMutation = useMutation(updateResource);

  const initialValues = {
    meta: {
      legalField: "",
      subLegalField: "",
    },
    customer: {
      email: "",
      telephone: "",
      gclid: "backoffice",
      person: {
        gender: "",
        givenName: "",
        familyName: "",
      },
      prelead: {
        initialProduct: "",
        landingPage: "Legalbird.io",
        partner: "",
      },
    },
    insurance: {
      caseReferenceNumber: "",
      insurancePolicyNumber: "",
      insuranceType: "full",
      deductible: "",
    },
    note: "",
  };

  const fetchCustomer = async (email: string) => {
    const customerCollection = await apiGet("customers", null, { email: email });
    if (customerCollection["hydra:totalItems"] === 0) {
      return null;
    }
    return _.head(customerCollection["hydra:member"]);
  };

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    const productName = values.meta.legalField === "trafficLaw" ? "traffic" : values.meta.subLegalField;

    //-- manipulate initialProduct
    let customerData = _.merge({}, values.customer);
    customerData.prelead.initialProduct = productName;

    let customer;

    switch (true) {
      case hasNoEmail:
        customerData.email = `advocard.error+${values.insurance.insurancePolicyNumber}@gmail.com`;
        const backofficeCases = await getBackofficeCasesForEmail(customerData.email);
        if (backofficeCases.length > 0) {
          customer = await fetchCustomer(customerData.email);
          break;
        }
        customer = await createMutation.mutateAsync({ uri: "customers", data: customerData });
        break;
      default:
        customer =
          customerStatus === "newCustomer"
            ? await createMutation.mutateAsync({ uri: "customers", data: customerData })
            : await fetchCustomer(customerData.email);
        break;
    }

    if (!customer) {
      alert("Es ist ein technischer Fehler beim Auffinden des bestehenden Kunden aufgetreten!");
      return;
    }

    //-- add product
    const productUri = getProductData(productName, "apiUri");
    let productData: Record<string, any> = {
      customer: customer["@id"],
      acquisitionPartner: values.customer.prelead.partner,
      createdBy: isCallcenterUser ? "backoffice_callcenter" : "backoffice",
    };
    if (values.meta.legalField === "trafficLaw") {
      productData.accusation = values.meta.subLegalField;
    }
    const productEntity = await createMutation.mutateAsync({ uri: productUri, data: productData });

    if (_.includes(["advocard", "roland", "arag"], values.customer.prelead.partner)) {
      //-- update insurance data
      const insuranceEntity = productEntity.insurance;
      let insuranceValues = _.cloneDeep(values.insurance);
      insuranceValues.deductible = insuranceValues.deductible ? convertToFloat(insuranceValues.deductible) : null;
      await updateMutation.mutateAsync({
        id: insuranceEntity.id,
        uri: "customer_insurances",
        data: insuranceValues,
      });
    }

    const productEntityWithBackofficeCase = await apiGet(productUri, productEntity.id);

    if (!_.isEmpty(values.note)) {
      await createMutation.mutateAsync({
        uri: "notes",
        data: {
          content: values.note,
          pinned: true,
          case: productEntityWithBackofficeCase.backofficeCase["@id"],
        },
      });
    }

    enqueueSnackbar("Mandant erfolgreich angelegt", {

      variant:"custom",
      buttonType: "link",
      buttonLink: getCaseLink(productEntity, isCallcenterUser ? "/callcenter" : ""),
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 10000,
    });
    clearForm();
  };

  const getSubLegalFields = (partner: string, legalField: string) => {
    switch (legalField) {
      case "familyLaw":
        return [
          <MenuItem key={"divorce"} value={"divorce"}>
            Trennung/Scheidung
          </MenuItem>,
          <MenuItem key={"alimony"} value={"alimony"}>
            Unterhalt
          </MenuItem>,
          <MenuItem key={"death"} value={"death"}>
            Erbe/Todesfall
          </MenuItem>,
          <MenuItem key={"familyLawCase"} value={"familyLawCase"}>
            Anderes Thema
          </MenuItem>,
        ];
      case "trafficLaw":
        return [
          <MenuItem key={"speeding"} value={"speeding"}>
            Geschwindigkeitsverstoß
          </MenuItem>,
          <MenuItem key={"red_light"} value={"red_light"}>
            Rotlichtverstoß
          </MenuItem>,
          <MenuItem key={"distance"} value={"distance"}>
            Abstandsverstoß
          </MenuItem>,
          <MenuItem key={"other"} value={"other"}>
            Handy/Drogen/Parken/Sonstiges
          </MenuItem>,
        ];
      case "employmentLaw":
        const availableFields = [];
        if (partner === "wbs") {
          availableFields.push(
            <MenuItem key={"settlement"} value={"settlement"}>
              {getProductData("settlement", "label")}
            </MenuItem>
          );
          {
            /*
            <MenuItem key={"employmentContract"} value={"employmentContract"}>
              {getProductData("employmentContract", "label")}
            </MenuItem>,
            */
          }
        }
        return availableFields;
      case "contractLaw":
        return [
          <MenuItem key={"purchaseContract"} value={"purchaseContract"}>
            {getProductData("purchaseContract", "label")}
          </MenuItem>,
          <MenuItem key={"vehicleContract"} value={"vehicleContract"}>
            {getProductData("vehicleContract", "label")}
          </MenuItem>,
          <MenuItem key={"serviceContract"} value={"serviceContract"}>
            {getProductData("serviceContract", "label")}
          </MenuItem>,
          <MenuItem key={"membershipContract"} value={"membershipContract"}>
            {getProductData("membershipContract", "label")}
          </MenuItem>,
          <MenuItem key={"subscriptionContract"} value={"subscriptionContract"}>
            {getProductData("subscriptionContract", "label")}
          </MenuItem>,
          <MenuItem key={"providerContract"} value={"providerContract"}>
            {getProductData("providerContract", "label")}
          </MenuItem>,
          <MenuItem key={"dataLeakContract"} value={"dataLeakContract"}>
            {getProductData("dataLeakContract", "label")}
          </MenuItem>,
          <MenuItem key={"travelContract"} value={"travelContract"}>
            {getProductData("travelContract", "label")}
          </MenuItem>,
          <MenuItem key={"eventContract"} value={"eventContract"}>
            {getProductData("eventContract", "label")}
          </MenuItem>,
          <MenuItem key={"otherContract"} value={"otherContract"}>
            {getProductData("otherContract", "label")}
          </MenuItem>,
        ];
      case "tenancyLaw":
        return [
          <MenuItem key={"rentalContract"} value={"rentalContract"}>
            {getProductData("rentalContract", "label")}
          </MenuItem>,
        ];
      default:
        return [];
    }
  };

  const hasLegalField = (legalField: string, partner: string) => {
    const legalFields = {
      //advovard: ["familyLaw", "trafficLaw", "employmentLaw", "contractLaw", "tenancyLaw"],
      advovard: ["familyLaw", "trafficLaw", "employmentLaw", "contractLaw"],
      arag: ["familyLaw", "trafficLaw"],
      roland: ["familyLaw"],
      wbs: ["trafficLaw", "employmentLaw", "tenancyLaw"],
    };

    const isLegalFieldsKey = (key: string): key is keyof typeof legalFields => key in legalFields;

    if (!partner || !isLegalFieldsKey(partner)) {
      return true;
    }

    return _.includes(legalFields[partner], legalField);
  };

  const { values, handleChange, handleSubmit, handleBlur, clearForm, registerValidators, errors, isLoading } = useForm({
    initialValues,
    onSubmit,
    identifier: "registerCustomer",
  });

  const updateInsuranceNumberValidators = (partner: string) => {
    if (!["advocard", "roland"].includes(partner)) {
      registerValidators("insurance.insurancePolicyNumber", []);
      return;
    }

    let newValidators: ValidatorType[] = hasNoEmail
      ? [...requiredFieldDefault]
      : atLeastOneFieldRequiredDefault("insurance.caseReferenceNumber");

    switch (partner) {
      case "roland":
        newValidators.push({
          validator: matchRegexp,
          params: [/^(01|04)[a-z0-9]{9}$/i],
          message: "Bitte eine gültige Versicherungsnummer eingeben",
        });
        break;
      default:
        break;
    }
    registerValidators("insurance.insurancePolicyNumber", newValidators);
  };

  const handlePartnerChange: ValidatorSelectProps["onChange"] = (e) => {

    handleChange({ target: { type: "text", name: "meta.legalField", value: "" } });
    handleChange({ target: { type: "text", name: "meta.subLegalField", value: "" } });

    switch (e.target.value) {
      case "advocard":
        registerValidators("insurance.caseReferenceNumber", [
          ...atLeastOneFieldRequiredDefault("insurance.insurancePolicyNumber"),
          {
            validator: matchRegexp,
            params: ["^\\d{2}-?(33-?0001-?|\\d)\\d{5}-?\\d$"],
            message: "Bitte eine valide Advocard Schadennummer angeben",
          },
          {
            validator: caseReferenceNumberIsUnique,
            params: [""],
            message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
          },
        ]);
        break;
      case "arag":
        registerValidators("insurance.caseReferenceNumber", [
          ...requiredFieldDefault,
          {
            validator: caseReferenceNumberIsUnique,
            params: [""],
            message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
          },
        ]);
        break;
      case "roland":
        registerValidators("insurance.caseReferenceNumber", [
          ...atLeastOneFieldRequiredDefault("insurance.insurancePolicyNumber"),
          {
            validator: caseReferenceNumberIsUnique,
            params: [""],
            message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
          },
        ]);
        break;
      case "wbs":
        registerValidators("insurance.caseReferenceNumber", [
          {
            validator: caseReferenceNumberIsUnique,
            params: [""],
            message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
          },
        ]);
        break;
      default:
        return;
    }
    updateInsuranceNumberValidators(e.target.value);
    handleChange(e);
  };

  const getBackofficeCasesForEmail = async (email: string) => {
    const backofficeCasesResponse = await ApiClient.get("backoffice_cases?customerEmail=" + encodeURIComponent(email));
    return backofficeCasesResponse["hydra:member"];
  };

  const handleDuplicateBlur: ValidatorSelectProps["onBlur"] = async (e) => {
    handleBlur(e);
    if (values.customer.email === "") {
      return;
    }
    setCustomerStatus("notChecked");
    setIsLoadingInternal(true);
    const customer = await fetchCustomer(values.customer.email.toLowerCase());
    setCustomerStatus(!!customer ? "existingCustomer" : "newCustomer");
    setIsLoadingInternal(false);
  };

  useEffect(() => {
    updateInsuranceNumberValidators(formValue(values, "customer.prelead.partner"));
    if (hasNoEmail) {
      registerValidators("customer.email", []);
      handleChange({ target: { name: "customer.email", value: "" } });
      handleBlur({ target: { name: "customer.email", value: "" } });
      return;
    }
    registerValidators("customer.email", [...emailDefault, ...requiredFieldDefault]);
  }, [hasNoEmail]);

  return (
    <>
      <PageHeadline main="Mandant/Fall anlegen" />
      <ContentBox>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} alignItems={"flex-start"}>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={"Kooperationspartner"}
                name={"customer.prelead.partner"}
                value={formValue(values, "customer.prelead.partner")}
                onChange={handlePartnerChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.prelead.partner"]}
                helperText={errors["customer.prelead.partner"]}
              >
                <MenuItem value={"arag"}>ARAG</MenuItem>
                <MenuItem value={"advocard"}>Advocard</MenuItem>
                <MenuItem value={"roland"}>Roland</MenuItem>
                {!isCallcenterUser && <MenuItem value={"wbs"}>WBS Rechtsanwälte</MenuItem>}
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={"Rechtsbereich"}
                name={"meta.legalField"}
                value={formValue(values, "meta.legalField")}
                onChange={(event) => {
                  handleChange({ target: { type: "text", name: "meta.subLegalField", value: "" } });
                  handleChange(event);
                }}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["meta.legalField"]}
                helperText={errors["meta.legalField"]}
              >
                {hasLegalField("familyLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"familyLaw"}>Familienrecht</MenuItem>
                )}
                {hasLegalField("contractLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"contractLaw"}>Vertragsrecht</MenuItem>
                )}
                {hasLegalField("trafficLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"trafficLaw"}>Verkehrsrecht</MenuItem>
                )}
                {hasLegalField("employmentLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"employmentLaw"}>Arbeitsrecht</MenuItem>
                )}
                {hasLegalField("tenancyLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"tenancyLaw"}>Mietrecht</MenuItem>
                )}
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={values.meta.legalField !== "trafficLaw" ? "Thema" : "Vorwurf"}
                name={"meta.subLegalField"}
                value={formValue(values, "meta.subLegalField")}
                onChange={handleChange}
                onBlur={handleDuplicateBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["meta.subLegalField"]}
                helperText={errors["meta.subLegalField"]}
              >
                {getSubLegalFields(values.customer.prelead.partner, values.meta.legalField)}
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={"Anrede"}
                name={"customer.person.gender"}
                value={formValue(values, "customer.person.gender")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.person.gender"]}
                helperText={errors["customer.person.gender"]}
              >
                <MenuItem value={"female"}>Frau</MenuItem>
                <MenuItem value={"male"}>Herr</MenuItem>
                <MenuItem value={"diverse"}>Divers</MenuItem>
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.person.givenName"
                label="Vorname"
                value={formValue(values, "customer.person.givenName")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.person.givenName"]}
                helperText={errors["customer.person.givenName"]}
                isHighlighted={undefined}
                initialDependentValidationFields={undefined}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.person.familyName"
                label="Nachname"
                value={formValue(values, "customer.person.familyName")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.person.familyName"]}
                helperText={errors["customer.person.familyName"]}
                isHighlighted={undefined}
                initialDependentValidationFields={undefined}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.email"
                label="E-Mail-Adresse"
                value={formValue(values, "customer.email")}
                onChange={handleChange}
                onBlur={handleDuplicateBlur}
                disabled={values.meta.subLegalField === "" || hasNoEmail}
                registerValidators={registerValidators}
                validators={[...emailDefault, ...requiredFieldDefault]}
                error={!!errors["customer.email"]}
                helperText={errors["customer.email"]}
                isHighlighted={undefined}
                initialDependentValidationFields={undefined}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Stack justifyContent={"center"} alignItems={"center"} sx={{ height: "80px" }}>
                <FormControlLabel
                  disabled={
                    values.customer.prelead.partner !== "advocard" || _.includes(["trafficLaw"], values.meta.legalField)
                  }
                  control={<Checkbox checked={hasNoEmail} onChange={(e) => setHasNoEmail(e.target.checked)} />}
                  label={"Erstberatung ohne Email"}
                />
              </Stack>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.telephone"
                label="Telefonnummer"
                value={formValue(values, "customer.telephone")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={[...requiredFieldDefault, ...isPhoneNumberWithoutSpecialCharactersDefault]}
                error={!!errors["customer.telephone"]}
                helperText={errors["customer.telephone"]}
                isHighlighted={undefined}
                initialDependentValidationFields={undefined}
              />
            </Grid>
            {values.customer.prelead.partner !== "wbs" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorTextField
                  fullWidth={true}
                  name="insurance.caseReferenceNumber"
                  label={translate("insurance.caseReferenceNumber.label")}
                  value={formValue(values, "insurance.caseReferenceNumber")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  validators={[
                    {
                      validator: caseReferenceNumberIsUnique,
                      params: [""],
                      message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
                    },
                    ...atLeastOneFieldRequiredDefault("insurance.insurancePolicyNumber"),
                  ]}
                  error={!!errors["insurance.caseReferenceNumber"]}
                  helperText={errors["insurance.caseReferenceNumber"]}
                  isHighlighted={undefined}
                  initialDependentValidationFields={undefined}
                />
              </Grid>
            )}
            {["advocard", "roland"].includes(values.customer.prelead.partner) && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorTextField
                  fullWidth={true}
                  name="insurance.insurancePolicyNumber"
                  label={translate("insurance.insurancePolicyNumber.label")}
                  value={formValue(values, "insurance.insurancePolicyNumber")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors["insurance.insurancePolicyNumber"]}
                  helperText={errors["insurance.insurancePolicyNumber"]}
                  registerValidators={undefined}
                  validators={undefined}
                  isHighlighted={undefined}
                  initialDependentValidationFields={undefined}
                />
              </Grid>
            )}
            {values.customer.prelead.partner === "roland" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorSelect
                  label={"Art der Versicherung"}
                  name={"insurance.insuranceType"}
                  value={formValue(values, "insurance.insuranceType")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  validators={requiredFieldDefault}
                  error={!!errors["insurance.insuranceType"]}
                  helperText={errors["insurance.insuranceType"]}
                >
                  <MenuItem value={"full"}>Voll versichert</MenuItem>
                  <MenuItem value={"bonus"}>Nur Bonusberatung</MenuItem>
                  <MenuItem value={"uncovered"}>Nicht versichert</MenuItem>
                </ValidatorSelect>
              </Grid>
            )}
            {values.meta.legalField === "trafficLaw" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorNumberField
                  fullWidth={true}
                  name="insurance.deductible"
                  label="Selbstbehalt"
                  value={formValue(values, "insurance.deductible")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  error={!!errors["insurance.deductible"]}
                  helperText={errors["insurance.deductible"]}
                  numberType={"float"}
                  fieldType={"money"}
                />
              </Grid>
            )}
            {customerStatus === "existingCustomer" && (
              <Grid item xs={12}>
                <InfoBox>
                  Wichtiger Hinweis: Zu dieser E-Mail liegt bereits ein Kundenkonto vor. Es wird ein neuer Fall
                  angelegt.
                </InfoBox>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>Notiz</InputLabel>
              <TextField
                multiline
                rows={8}
                className={classes.textField}
                name={"note"}
                value={formValue(values, "note")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} xs={12} className={classes.buttonContainer}>
              <ButtonLoading
                variant={"contained"}
                type={"submit"}
                disabled={customerStatus === "notChecked" && !hasNoEmail}
                isLoading={isLoading || isLoadingInternal}
              >
                Jetzt anlegen
              </ButtonLoading>
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12} className={classes.buttonContainer}>
              <ButtonLoading variant={"text"} onClick={() => clearForm()}>
                Abbrechen
              </ButtonLoading>
            </Grid>
            <Grid item md={4} />
          </Grid>
        </form>
      </ContentBox>
    </>
  );
};

export default CreateCase;
