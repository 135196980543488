import React, { useState } from "react";
import casePaidTaskStyle from "./casePaidTaskStyle";
import { Box, Stack, Typography } from "@mui/material";
import { getApprovalCardEnabled } from "../../services/paidTaskService";
import { transformFromFormValues } from "../../services/formServiceFunctions";
import moment from "moment";
import _ from "lodash";
import { getCaseLink, getProductData } from "../../services/Product/ProductService";
import { ContentContainer } from "../Container/PageContentContainer";
import { useSnackbar } from "notistack";
import { PAID_TASK_STATUS_IN_REVIEW } from "../PaidTask/paidTaskStatus";
import ContentBox from "../ContentBox/ContentBox";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { FormContainer, RadioButtonGroup, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { translate } from "../../services/Translations/translatorService";
import { requiredFieldDefault, textFieldDefault } from "../../services/reactHookFormValidationRules";
import { PaidTask } from "../../types/PaidTask";
import { AbstractCase } from "../../types/AbstractCase";

type CasePaidTaskApprovalProps = {
  paidTask: PaidTask;
  product: AbstractCase;
};

const CasePaidTaskApproval = ({ paidTask, product }: CasePaidTaskApprovalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmRequestChangesOpen, setConfirmRequestChangesOpen] = useState(false);
  const [confirmApprovePaidTaskOpen, setConfirmApprovePaidTaskOpen] = useState(false);
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();

  const initialValues = {
    reviewRemarks: paidTask.reviewRemarks || "",
    action: paidTask.reviewRemarks ? "requestChanges" : "approve",
    notifyCustomer: "",
  };

  const formContext = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const handleSubmit = () => {
    if (formContext.getValues().action === "requestChanges") {
      setConfirmRequestChangesOpen(true);
    } else {
      setConfirmApprovePaidTaskOpen(true);
    }
  };

  const handleConfirmChangesRequested = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        reviewRemarks: formContext.getValues().reviewRemarks,
        changesRequested: true,
      },
    });
    setIsLoading(false);
    enqueueSnackbar("Änderungen wurden angefragt", {
      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLink(product),
      buttonTarget: "_self",
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 3000,
    });
    setConfirmRequestChangesOpen(false);
  };

  const handleConfirmDone = async () => {
    setIsLoading(true);

    //----update paidtask
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        approvedDate: moment().format("DD.MM.YYYY"),
        approvedBy: currentUser["@id"],
        notifyCustomer: formContext.getValues().notifyCustomer === "yes",
      },
    });
    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));
    //----update product entity with temporarily saved fields filled in by external user
    let updateData = _.cloneDeep(paidTask.formData);
    //remove fields that have nothing to do with the entities. for example editor fields
    updateData = _.mapKeys(updateData, (value, key) => {
      if (_.startsWith(key.toString(), "temporaryField.")) {
        return _.replace(key.toString(), "temporaryField.", "");
      }
      return "notAFieldToPersist";
    });
    updateData = _.pickBy(updateData, (field, key) => key !== "notAFieldToPersist");
    updateData = transformFromFormValues(product.productClassName, updateData);
    const persist = getProductData(product.productClassName, "persist");
    await persist(updateData, product);

    //---- notify user
    enqueueSnackbar("Aufgabe wurde abgenommen", {
      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLink(product),
      buttonTarget: "_self",
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 3000,
    });
    setIsLoading(false);
    setConfirmApprovePaidTaskOpen(false);
  };

  const isDisabled = !getApprovalCardEnabled(paidTask.status, currentUser);

  return (
    <FormContainer formContext={formContext} onSuccess={handleSubmit}>
      <Box
        sx={
          isDisabled
            ? {
                opacity: 0.5,
                pointerEvents: "none",
              }
            : {}
        }
      >
        <ContentBox headline="Abnahme" headlineVariant="h2">
          <ContentContainer size={"tiny"}>
            {(currentUser.isExternal || paidTask.status !== PAID_TASK_STATUS_IN_REVIEW) && (
              <TextFieldElement
                name={"reviewRemarks"}
                label={translate("paidTask.reviewRemarks")}
                value={formContext.watch("reviewRemarks") || ""}
                disabled={true}
                multiline={true}
              />
            )}
            {!currentUser.isExternal && paidTask.status === PAID_TASK_STATUS_IN_REVIEW && (
              <Stack direction={"column"} alignItems={"center"} spacing={2} sx={casePaidTaskStyle.buttonArea}>
                <Typography>Aufgabe abnehmen oder sind Änderungen notwendig?</Typography>
                <RadioButtonGroup
                  row
                  options={[
                    {
                      id: "approve",
                      label: "Aufgabe abnehmen",
                    },
                    { id: "requestChanges", label: "Änderungen notwendig" },
                  ]}
                  name={"action"}
                />
                {formContext.watch("action") === "requestChanges" && (
                  <TextFieldElement
                    name={"reviewRemarks"}
                    label={translate("paidTask.reviewRemarks")}
                    disabled={paidTask.status !== PAID_TASK_STATUS_IN_REVIEW || currentUser.isExternal}
                    validation={{
                      ...textFieldDefault,
                      ...requiredFieldDefault,
                    }}
                    fullWidth
                    multiline
                  />
                )}
                {formContext.watch("action") === "approve" && (
                  <Box width={"250px"}>
                    <SelectElement
                      label="Mandantschaft informieren?"
                      name="notifyCustomer"
                      options={[
                        {
                          id: "yes",
                          label: "Ja",
                        },
                        {
                          id: "no",
                          label: "Nein",
                        },
                      ]}
                      validation={requiredFieldDefault}
                    />
                  </Box>
                )}
                <ButtonLoading
                  variant={"contained"}
                  isLoading={isLoading}
                  sx={casePaidTaskStyle.submitButton}
                  type={"submit"}
                  fullWidth={false}
                >
                  Änderungen speichern
                </ButtonLoading>
              </Stack>
            )}
          </ContentContainer>
          <LegalbirdIoModal
            handleClose={() => setConfirmRequestChangesOpen(false)}
            open={confirmRequestChangesOpen}
            title={"Aufgabe zurückgeben?"}
            submitButton={
              <ButtonLoading variant={"contained"} onClick={handleConfirmChangesRequested} isLoading={isLoading}>
                Änderungen anfragen
              </ButtonLoading>
            }
          >
            Möchten Sie die Aufgabe wieder zurück in Bearbeitung geben und Änderungen beim zugewiesenen Anwalt anfragen?
          </LegalbirdIoModal>
          <LegalbirdIoModal
            handleClose={() => setConfirmApprovePaidTaskOpen(false)}
            open={confirmApprovePaidTaskOpen}
            title={"Aufgabe abnehmen?"}
            submitButton={
              <ButtonLoading variant={"contained"} onClick={handleConfirmDone} isLoading={isLoading}>
                Aufgabe abnehmen
              </ButtonLoading>
            }
          >
            Möchten Sie die Aufgabe abnehmen und zur Vergütung freigeben? Es werden dann alle Dateien und Informationen
            an den Fall übertragen. Ein Wieder-Öffnen der Aufgabe ist nicht möglich.
          </LegalbirdIoModal>
        </ContentBox>
      </Box>
    </FormContainer>
  );
};

export default CasePaidTaskApproval;
