import React, { useState } from "react";
import useStyles from "./createNoteStyles";
import { Box, Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createResource,
  fetchCollection,
  queryKeys,
  updateResource,
} from "../../../services/ReactQuery/reactQueryService";
import { AbstractCase } from "../../../types/AbstractCase";
import { Note } from "../../../types/Note";
import Typography from "@mui/material/Typography";
import ButtonLoading from "../../Button/ButtonLoading";

type EditNoteProps = {
  open: boolean;
  closeDialog: () => void;
  product: AbstractCase;
  note?: Note;
};

const EditNote = ({ open, closeDialog, product, note }: EditNoteProps) => {
  const classes = useStyles({});
  const queryClient = useQueryClient();
  const [pinned, setPinned] = useState(!!note ? note.pinned : true);
  const [isWarning, setIsWarning] = useState(!!note ? !!note.isWarning : false);
  const [editorState, setEditorState] = useState(htmlToEditorState(!!note && note.content));
  const createMutation = useMutation(createResource);
  const updateMutation = useMutation(updateResource);

  const pinnedNotesFilters = {
    case: product.backofficeCase["@id"],
    pinned: true,
  };
  const { data: pinnedNotes } = useQuery(queryKeys.collection("notes", pinnedNotesFilters), () =>
    fetchCollection("notes", pinnedNotesFilters)
  );

  if (!pinnedNotes) {
    return <></>;
  }

  const pinnedNotesCount = pinnedNotes["hydra:totalItems"];

  const existingPinnedInNotes = !note
    ? false
    : pinnedNotes["hydra:member"].some((pinnedNote: Note) => pinnedNote.id === note.id);

  let pinnedLimitExceeded = false;

  if (pinnedNotesCount >= 4 && pinned && !existingPinnedInNotes) {
    pinnedLimitExceeded = true;
  }

  const saveNote = async () => {
    if (!product.backofficeCase?.["@id"]) {
      return;
    }
    if (note && note.id) {
      await updateMutation.mutateAsync({
        id: note.id,
        uri: "notes",
        data: {
          content: editorStateToHTML(editorState),
          pinned: pinned,
          isWarning: isWarning,
          case: product.backofficeCase["@id"],
        },
      });
      await queryClient.invalidateQueries(queryKeys.collections("notes"));
      closeDialog();
      return;
    }

    await createMutation.mutateAsync({
      uri: "notes",
      data: {
        content: editorStateToHTML(editorState),
        pinned: pinned,
        isWarning: isWarning,
        case: product.backofficeCase["@id"],
      },
    });
    await queryClient.invalidateQueries(queryKeys.collections("notes"));
    internalCloseDialog();
  };

  const internalCloseDialog = () => {
    if (!note) {
      setPinned(true);
      setIsWarning(false);
      setEditorState(EditorState.createEmpty());
    }
    closeDialog();
  };

  const saveDisabled = !!note
    ? //convert note content and editor content to html produced by editorState so that we are able to compare both
      note.pinned === pinned &&
      note.isWarning === isWarning &&
      editorStateToHTML(editorState) === editorStateToHTML(htmlToEditorState(note.content)) &&
      pinnedLimitExceeded
    : !editorState.getCurrentContent().hasText() || pinnedLimitExceeded;

  if (pinnedLimitExceeded) {
    return (
      <LegalbirdIoModal
        handleClose={internalCloseDialog}
        open={open}
        disableBackdropClick
        title={"Maximale Anzahl an Notizen erreicht"}
        hideCancelButton={true}
        submitButton={<ButtonLoading onClick={() => internalCloseDialog()}>Schließen</ButtonLoading>}
      >
        <Box>
          Es ist bereits die maximal mögliche Anzahl an Notizen am Fall angeheftet. Bitte vorhandene Notizen abheften
          oder aktualisieren. Sobald weniger als 4 Notizen am Fall sind, kann eine neue Notiz angelegt werden.
        </Box>
      </LegalbirdIoModal>
    );
  }
  return (
    <LegalbirdIoModal
      handleClose={internalCloseDialog}
      open={open}
      disableBackdropClick
      title={"Notiz " + (note ? "bearbeiten" : "erstellen")}
      submitButton={
        <Button variant={"contained"} onClick={saveNote} disabled={saveDisabled}>
          Speichern
        </Button>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Editor
            wrapperClassName={classes.editorWrapper}
            editorClassName={classes.editor}
            toolbar={{
              options: ["inline", "list", "history", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={editorState}
            onEditorStateChange={setEditorState}
            localization={{ locale: "de" }}
            spellCheck
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={pinned}
                onChange={({ target }) => setPinned(target.checked)}
                className={classes.checkbox}
              />
            }
            label="Notiz am Fall anheften"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isWarning}
                onChange={({ target }) => setIsWarning(target.checked)}
                className={classes.checkbox}
              />
            }
            label="Als Warnung anzeigen"
          />
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
};
export default EditNote;
