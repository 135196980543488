import React from "react";
import {CustomContentProps, SnackbarContent, useSnackbar} from "notistack";
import useStyles from "./snackbarStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type CustomSnackbarProps = {
  buttonText?: string;
  buttonType?: "link" | "function";
  buttonLink?: string;
  buttonTarget?: string;
  buttonFunction?: () => void;
  isNonInteractive?: boolean;
};


export const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomSnackbarProps & CustomContentProps>(
  (
    {
      message = "Änderung wurde gespeichert",
      buttonText = "Rückgängig",
      buttonType = "link",
      buttonLink,
      buttonTarget = "_blank",
      buttonFunction,
      isNonInteractive = false,
    },
    ref
  ) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    return (
      <SnackbarContent ref={ref}>
        <Paper className={classes.assignedUserToast}>
          <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={2}>
            <Grid item xs={isNonInteractive ? 10 : 6} className={isNonInteractive ? classes.message : undefined}>
              {message}
            </Grid>
            {!isNonInteractive && (
              <>
                <Grid item xs={4} className={classes.alignRight}>
                  {buttonType === "link" && buttonLink && (
                    <Button component={Link} className={classes.revertButton} to={buttonLink} target={buttonTarget}>
                      {buttonText}
                    </Button>
                  )}
                  {buttonType === "function" && buttonFunction && (
                    <Button className={classes.revertButton} onClick={buttonFunction}>
                      {buttonText}
                    </Button>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={2} className={classes.alignRight}>
              <IconButton
                onClick={() => {
                  closeSnackbar();
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
        );
      </SnackbarContent>
    );
  }
);
