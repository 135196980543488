import React, { useState } from "react";
import uploadFileStyles from "./uploadFileStyles";
import { DropzoneArea } from "mui-file-dropzone";
import MediaObjectFormModal from "./MediaObjectFormModal";
import { Box } from "@mui/material";
import DocumentClassLabelAiProvider from "../../../provider/DocumentClassLabelAiProvider";
import ActionIdentifierProvider from "../../../provider/ActionIdentifierProvider";
import { AbstractCase } from "../../../types/AbstractCase";

const FILE_SIZE_LIMIT_WARNING = 10_485_760;
const FILE_SIZE_LIMIT_EXCEEDED = 20_971_520;

type UploadFileProps = {
  open: boolean;
  closeDialog: Function;
  product: AbstractCase;
};

const UploadFile = ({ open, closeDialog, product }: UploadFileProps) => {
  const [files, setFiles] = useState<File[]>([]);

  const resetDialog = () => {
    setFiles([]);
    closeDialog();
  };

  const fileSize = files[0] ? files[0].size : 0;

  const fileContainerStyles =
    fileSize > FILE_SIZE_LIMIT_WARNING
      ? { ...uploadFileStyles.fileContainer, ...uploadFileStyles.borderWarning }
      : { ...uploadFileStyles.fileContainer, ...uploadFileStyles.border };

  return (
    <DocumentClassLabelAiProvider>
      <ActionIdentifierProvider>
        <MediaObjectFormModal
          product={product}
          closeDialog={resetDialog}
          open={open}
          fileData={{ file: files[0] }}
          submitDisabled={!files[0] || fileSize >= FILE_SIZE_LIMIT_EXCEEDED}
        >
          <Box sx={{ textAlign: "center" }}>
            <Box sx={fileContainerStyles}>
              <DropzoneArea
                onChange={(files) => {
                  setFiles(files);
                }}
                filesLimit={1}
                dropzoneText={"Datei hierher ziehen oder Fläche anklicken"}
                showFileNames
                showAlerts={false}
                maxFileSize={300000000}
                useChipsForPreview
                fileObjects={files}
                onDelete={() => {}}
              />
            </Box>
            <br />
            {fileSize > FILE_SIZE_LIMIT_WARNING && fileSize < FILE_SIZE_LIMIT_EXCEEDED && (
              <Box sx={uploadFileStyles.warning}>
                Achtung: Die Datei ist über 10 MB groß. Wollen Sie diese trotzdem hochladen?
              </Box>
            )}
            {fileSize >= FILE_SIZE_LIMIT_EXCEEDED && (
              <Box sx={uploadFileStyles.warning}>
                Achtung: Die Datei ist über 20 MB groß und kann nicht hochgeladen werden.
              </Box>
            )}
          </Box>
        </MediaObjectFormModal>
      </ActionIdentifierProvider>
    </DocumentClassLabelAiProvider>
  );
};

export default UploadFile;
