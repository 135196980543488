import { useEffect, useState } from "react";
import { apiGet } from "../../../services/Api/apiCall";
import { Wiretransfer } from "../../../types/Wiretransfer";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import AddWiretransfer from "../../Wiretransfer/AddWiretransfer";
import WiretransferList from "../../Wiretransfer/WiretransferList";

export default function WiretransferPage() {
  const [wiretransferCollection, setWiretransferCollection] = useState<Wiretransfer[] | null>(null);

  const fetchWiretransfers = () => {
    apiGet("wiretransfers?exists[paymentRun]=false&exists[deletedDate]=false").then((result) =>
      setWiretransferCollection(result["hydra:member"])
    );
  };

  useEffect(() => {
    fetchWiretransfers();
  }, []);

  return (
    <PageContentContainer>
      <PageHeadline main={"Überweisungen"} />
      <AddWiretransfer postSubmitCallback={fetchWiretransfers} />
      <WiretransferList wiretransferCollection={wiretransferCollection} refreshCollection={fetchWiretransfers} />
    </PageContentContainer>
  );
}
