import {
  Box,
  ButtonGroup,
  Checkbox,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import { convertBooleanToYesNoString } from "../../services/formServiceFunctions";
import { Wiretransfer } from "../../types/Wiretransfer";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { apiPost, apiPut } from "../../services/Api/apiCall";
import { Link } from "react-router-dom";
import { getCaseLinkByBackofficeCase } from "../../services/Product/ProductService";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import { DeleteOutline, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import _ from "lodash";
import Button from "@mui/material/Button";
import { userHasOneOfTheseRoles, userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import ButtonLoading from "../Button/ButtonLoading";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function WiretransferList({
  wiretransferCollection,
  refreshCollection,
}: {
  wiretransferCollection: Wiretransfer[] | null;
  refreshCollection: Function;
}) {
  const currentUser = useCurrentUser();
  const [filter, setFilter] = useState<string>(localStorage.getItem("wiretransferFilter") || "created");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("wiretransferFilter", filter);
  }, [filter]);

  const buttons = [
    {
      label: "Erstellt",
      filterName: "created",
    },
    {
      label: "Sachlich geprüft",
      filterName: "factualCorrect",
    },
    {
      label: "Rechnerisch geprüft",
      filterName: "calculativeCorrect",
    },
  ];

  const filteredList = useMemo(() => {
    if (wiretransferCollection === null) {
      return [];
    }
    return wiretransferCollection.filter((wiretransfer) => {
      switch (filter) {
        case "created":
          return !wiretransfer.factualCorrectDate && !wiretransfer.calculativeCorrectDate;
        case "factualCorrect":
          return !!wiretransfer.factualCorrectDate && !wiretransfer.calculativeCorrectDate;
        case "calculativeCorrect":
          return !!wiretransfer.factualCorrectDate && !!wiretransfer.calculativeCorrectDate;
        default:
          return false;
      }
    });
  }, [wiretransferCollection, filter]);

  const createPaymentRun = async () => {
    setIsLoading(true);
    await apiPost("/lb/services/payment_run/wiretransfer", {});
    refreshCollection();
    setIsLoading(false);
  };

  if (wiretransferCollection === null) {
    return (
      <ContentBox headline={"Überweisungen"}>
        <LegalbirdLoader centered />
      </ContentBox>
    );
  }

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <ButtonGroup color="primary">
          {_.map(buttons, (button) => (
            <Button
              key={button.filterName}
              variant={filter === button.filterName ? "contained" : "outlined"}
              onClick={() => setFilter(button.filterName)}
            >
              {button.label}
            </Button>
          ))}
        </ButtonGroup>
      </Stack>
      <ContentBox headline={"Überweisungen"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Eingestellt am</TableCell>
              <TableCell>Empfänger</TableCell>
              <TableCell>Betrag</TableCell>
              <TableCell>Verwendungszweck</TableCell>
              <TableCell>Fall</TableCell>
              <TableCell>Anwalt</TableCell>
              <TableCell>Sachlich geprüft</TableCell>
              <TableCell>Fallbezogene Zahlung?</TableCell>
              <TableCell>Rechnerisch geprüft</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList.map((wiretransfer) => (
              <WiretransferRow
                key={wiretransfer.id}
                wiretransfer={wiretransfer}
                refreshCollection={refreshCollection}
              />
            ))}
          </TableBody>
        </Table>
        {userHasRole(currentUser, Roles.admin) && filter === "calculativeCorrect" && filteredList.length > 0 && (
          <Box sx={{ textAlign: "center", paddingTop: "2rem" }}>
            <ButtonLoading
              sx={{ minWidth: "230px" }}
              variant={"contained"}
              fullWidth={false}
              isLoading={isLoading}
              onClick={createPaymentRun}
            >
              Zahllauf erstellen
            </ButtonLoading>
          </Box>
        )}
      </ContentBox>
    </>
  );
}

function WiretransferRow({
  wiretransfer,
  refreshCollection,
}: {
  wiretransfer: Wiretransfer;
  refreshCollection: Function;
}) {
  const [factualCorrect, setFactualCorrect] = useState<boolean>(!!wiretransfer.factualCorrectDate);
  const [calculativeCorrect, setCalculativeCorrect] = useState<boolean>(!!wiretransfer.calculativeCorrectDate);
  const [bookToCase, setBookToCase] = useState<string>(convertBooleanToYesNoString(wiretransfer.bookToCase));
  const [modal, setModal] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const currentUser = useCurrentUser();
  const { getUserData } = useBackofficeUser();

  const toggleFactualCorrect = (value: boolean) => {
    apiPut("wiretransfers", wiretransfer.id, {
      factualCorrectDate: value ? moment() : null,
      factualCorrectBy: value ? currentUser["@id"] : null,
    }).then(() => refreshCollection());
    setFactualCorrect(value);
  };

  const toggleCalculativeCorrect = (value: boolean) => {
    apiPut("wiretransfers", wiretransfer.id, {
      calculativeCorrectDate: value ? moment() : null,
      calculativeCorrectBy: value ? currentUser["@id"] : null,
    }).then(() => refreshCollection());
    setCalculativeCorrect(value);
  };

  const handleBookToCase = (value: string) => {
    if (value === "") {
      return;
    }
    apiPut("wiretransfers", wiretransfer.id, {
      bookToCase: value === "yes",
    }).then(() => refreshCollection());
    setBookToCase(value);
  };

  const isDeletable = () => {
    return userHasOneOfTheseRoles(currentUser, [Roles.accounting, Roles.admin]) || !wiretransfer.factualCorrectDate;
  };

  const handleDelete = () => {
    apiPut("wiretransfers", wiretransfer.id, {
      deletedDate: moment(),
      deletedBy: currentUser["@id"],
    }).then(() => refreshCollection());
  };

  const createdByUser = getUserData(wiretransfer.createdBy);
  const factualCorrectByUser = getUserData(wiretransfer.factualCorrectBy);
  const calculativeCorrectByUser = getUserData(wiretransfer.calculativeCorrectBy);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(wiretransfer.created).format("DD.MM.YYYY HH:mm")} Uhr</TableCell>
        <TableCell>{wiretransfer.recipientName}</TableCell>
        <TableCell>{wiretransfer.amount} €</TableCell>
        <TableCell>{wiretransfer.subject}</TableCell>
        <TableCell>
          {!!wiretransfer.backofficeCase ? (
            <Link to={getCaseLinkByBackofficeCase(wiretransfer.backofficeCase)} target={"_blank"}>
              {wiretransfer.backofficeCase.label}
            </Link>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>{_.get(wiretransfer, "backofficeCase.lawyer.person.fullname", "-")}</TableCell>
        <TableCell>
          <Checkbox
            name="factualCorrect"
            checked={factualCorrect}
            onChange={() => setModal("factualCorrect")}
            value="factualCorrect"
            color="primary"
            disabled={
              !!wiretransfer.calculativeCorrectDate ||
              !userHasOneOfTheseRoles(currentUser, [Roles.lawyer, Roles.admin, Roles.accounting])
            }
          />
          <LegalbirdIoConfirm
            handleClose={() => setModal("")}
            open={modal === "factualCorrect"}
            headline={!factualCorrect ? "Sachliche Prüfung" : "Rücknahme - Sachliche Prüfung"}
            handleConfirm={() => toggleFactualCorrect(!factualCorrect)}
            confirmText={"Bestätigen"}
            content={
              !factualCorrect
                ? "Bitte bestätigen, dass der Eintrag sachlich geprüft wurde"
                : "Bitte bestätigen, dass die sachliche Prüfung zurückgenommen werden soll."
            }
          />
        </TableCell>
        <TableCell>
          {!!wiretransfer.caseId && (
            <FormControl fullWidth>
              <InputLabel shrink>Fallbezogene Zahlung?</InputLabel>
              <Select
                input={<OutlinedInput notched label={"Fallbezogene Zahlung?"} />}
                value={bookToCase}
                onChange={({ target }) => handleBookToCase(target.value)}
                disabled={
                  !wiretransfer.factualCorrectDate ||
                  !userHasOneOfTheseRoles(currentUser, [Roles.accounting, Roles.admin])
                }
              >
                <MenuItem value={""} disabled>
                  {" "}
                </MenuItem>
                <MenuItem value={"yes"}>Ja</MenuItem>
                <MenuItem value={"no"}>Nein</MenuItem>
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell>
          <Checkbox
            name="calculativeCorrect"
            checked={calculativeCorrect}
            onChange={() => setModal("calculativeCorrect")}
            value="calculativeCorrect"
            color="primary"
            disabled={
              !wiretransfer.factualCorrectDate ||
              (!!wiretransfer.caseId && wiretransfer.bookToCase === null) ||
              !userHasOneOfTheseRoles(currentUser, [Roles.accounting, Roles.admin])
            }
          />
          <LegalbirdIoConfirm
            handleClose={() => setModal("")}
            open={modal === "calculativeCorrect"}
            headline={!calculativeCorrect ? "Rechnerische Prüfung" : "Rücknahme - Rechnerische Prüfung"}
            handleConfirm={() => toggleCalculativeCorrect(!calculativeCorrect)}
            confirmText={"Bestätigen"}
            content={
              !calculativeCorrect
                ? "Bitte bestätigen, dass der Eintrag rechnerisch geprüft wurde."
                : "Bitte bestätigen, dass die rechnerische Prüfung zurückgenommen werden soll."
            }
          />
        </TableCell>
        <TableCell>
          {isDeletable() && (
            <>
              <IconButton onClick={() => setModal("delete")} size="large">
                <DeleteOutline />
              </IconButton>
              <LegalbirdIoConfirm
                handleClose={() => setModal("")}
                open={modal === "delete"}
                headline={"Überweisung löschen?"}
                handleConfirm={() => handleDelete()}
                confirmText={"Löschen"}
                content={"Möchten Sie den Eintrag wirklich löschen?"}
              />
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingTop: "0px" }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Box>
                <strong>IBAN</strong>: {wiretransfer.iban}
              </Box>
              <Box>
                <strong>Erstellt:</strong>: {moment(wiretransfer.created).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
                {createdByUser?.person.fullname}
              </Box>
              {!!wiretransfer.factualCorrectDate && (
                <Box>
                  <strong>Sachlich geprüft:</strong>:{" "}
                  {moment(wiretransfer.factualCorrectDate).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
                  {factualCorrectByUser?.person.fullname}
                </Box>
              )}
              {!!wiretransfer.calculativeCorrectDate && (
                <Box>
                  <strong>Rechnerisch geprüft:</strong>:{" "}
                  {moment(wiretransfer.calculativeCorrectDate).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
                  {calculativeCorrectByUser?.person.fullname}
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
