import { Wiretransfer } from "../types/Wiretransfer";

export function getWiretransferDisplayStatus(wiretransfer: Wiretransfer) {
  if (!!wiretransfer.paymentRun) {
    return "Im Zahllauf";
  }
  if (!!wiretransfer.factualCorrectDate && !wiretransfer.calculativeCorrectDate) {
    return "Sachlich geprüft";
  }
  if (!!wiretransfer.factualCorrectDate && !!wiretransfer.calculativeCorrectDate) {
    return "Rechnerisch geprüft";
  }
  return "Erstellt";
}
