import {
  AccountBalanceOutlined,
  AssignmentIndOutlined,
  BuildOutlined,
  CalendarTodayOutlined,
  CategoryOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  DeleteForeverOutlined,
  EmailOutlined,
  EuroOutlined,
  Event,
  EventAvailableOutlined,
  FindInPageOutlined,
  HistoryEduOutlined,
  KeyboardTabOutlined,
  ListOutlined,
  MarkEmailReadOutlined,
  MonetizationOnOutlined,
  NewspaperOutlined,
  NextPlanOutlined,
  NoteAddOutlined,
  PasswordOutlined,
  PauseCircleOutlined,
  PersonAddOutlined,
  PlaylistAddCheckOutlined,
  PlaylistAddOutlined,
  PostAddOutlined,
  QuestionAnswerOutlined,
  RestoreOutlined,
  SendOutlined,
  SpeakerNotesOutlined,
  SubjectOutlined,
  UndoOutlined,
  SentimentDissatisfiedOutlined,
} from "@mui/icons-material";
import { getCaseLink, getNextStage, getProductData } from "./Product/ProductService";
import _ from "lodash";
import { buildCalendlyLink } from "./calendlyService";
import * as stagesTraffic from "./Stages/StagesTraffic";
import * as stagesSettlement from "./Stages/StagesSettlement";
import * as stagesDivorce from "./Stages/StagesDivorce";
import * as stagesCase from "./Stages/StagesCase";
import { isMobile } from "react-device-detect";
import { userHasRole } from "./backofficeUserService";
import { Roles } from "../types/BackofficeUser";
import * as caseStatus from "./LeadStatus/StatusCase";

export const getGeneralActionsByUser = (currentUser) => {
  switch (true) {
    case userHasRole(currentUser, Roles.external):
      return [
        {
          icon: DashboardOutlined,
          text: "Übersicht",
          to: "/",
        },
        {
          icon: CalendarTodayOutlined,
          text: "Termine",
          to: "/services/extern/termine",
          isHidden: !currentUser.calendlyId,
        },
        {
          icon: ListOutlined,
          text: "Aufgaben",
          to: "/services/extern/aufgaben",
        },
        {
          icon: PlaylistAddOutlined,
          text: "Ausschreibungen",
          to: "/services/extern/ausgeschriebene-aufgaben",
        },
        {
          icon: NewspaperOutlined,
          text: "Updates",
          to: "/services/extern/release-updates",
        },
      ];
    case userHasRole(currentUser, Roles.callcenter):
      return [
        {
          icon: PersonAddOutlined,
          text: "Neuer Fall",
          to: "/services/callcenter/fall-anlegen",
        },
      ];
    default:
      return [
        {
          icon: ListOutlined,
          text: "Aktivitäten",
          to: "/services/aktivitaeten",
        },
        {
          icon: CalendarTodayOutlined,
          text: "Fristen",
          to: "/services/fristenkalender",
        },
        {
          icon: PersonAddOutlined,
          text: "Neuer Fall",
          to: "/services/tools/mandant-anlegen",
        },
        {
          icon: CategoryOutlined,
          text: "Produkte",
          to: "/services/board/uebersicht",
        },
        {
          icon: BuildOutlined,
          text: "Tools",
          to: "/services/tools",
        },
      ];
  }
};

export const caseRelatedActions = (product, setOpen, openAndMaximizeMessageDrawer, currentUser) => {
  //extra list atm since there are actions from the additional and default actions
  if (userHasRole(currentUser, Roles.callcenter)) {
    return [
      {
        text: "E-Mail neu",
        icon: MarkEmailReadOutlined,
        onClick: () => setOpen("resendEmail"),
        isHidden: product.customer.verification !== null,
      },
      {
        text: "Passwort neu",
        icon: PasswordOutlined,
        onClick: () => setOpen("resendEmail"),
        isHidden: product.customer.verification === null,
      },
      {
        text: "Telefonnotiz",
        onClick: () => setOpen("callNotes"),
        icon: SpeakerNotesOutlined,
      },
      {
        text: "Termin buchen",
        icon: EventAvailableOutlined,
        href: buildCalendlyLink(product, true),
        isHidden: !_.startsWith(product.customer.email, "advocard.error"),
      },
    ];
  }

  return [
    {
      text: "Aktivität",
      onClick: () => setOpen("activity"),
      icon: PlaylistAddCheckOutlined,
      isHidden: currentUser.isExternal,
    },
    {
      text: "Nachricht",
      onClick: () => openAndMaximizeMessageDrawer(),
      icon: EmailOutlined,
    },
    {
      text: "Notiz",
      onClick: () => setOpen("note"),
      icon: NoteAddOutlined,
    },
    {
      text: "Schriftsatz",
      to: getCaseLink(product) + "/datei-generieren",
      icon: PostAddOutlined,
      isHidden: currentUser.isExternal,
    },
    {
      text: "beA / Fax",
      onClick: () => setOpen("bea_fax"),
      icon: SendOutlined,
      isHidden: currentUser.isExternal,
    },
    {
      text: "Datei",
      onClick: () => setOpen("upload"),
      icon: CloudUploadOutlined,
      divider: !isMobile,
      isHidden: currentUser.isExternal,
    },
    {
      text: "Fallübersicht",
      to: getCaseLink(product, currentUser.isExternal ? "/extern" : ""),
      icon: AssignmentIndOutlined,
    },
    {
      text: "Alle Daten",
      to: getCaseLink(product, currentUser.isExternal ? "/extern" : "") + "/alle-daten",
      icon: SubjectOutlined,
    },
    {
      text: "Konto",
      to: getCaseLink(product) + "/konto",
      icon: AccountBalanceOutlined,
      isHidden: currentUser.isExternal,
    },
    {
      text: "Verlauf",
      to: getCaseLink(product) + "/verlauf",
      icon: RestoreOutlined,
      isHidden: currentUser.isExternal,
      divider: !isMobile,
    },
  ];
};

export const caseRelatedAdditionalActions = (product, setOpen) => [
  {
    text: "Anfrage stellen",
    icon: QuestionAnswerOutlined,
    onClick: () => setOpen("addRequest"),
  },
  {
    text: "Datenauskunft",
    icon: FindInPageOutlined,
    onClick: () => setOpen("requestDataCopy"),
    isHidden:
      product.productClassName !== "dataLeakContract" ||
      product.leadStatus < caseStatus.STATUS_DATA_COMPLETED ||
      product.leadStatus > caseStatus.STATUS_CHECKED,
  },
  {
    text: "Betroffenheit",
    icon: SentimentDissatisfiedOutlined,
    onClick: () => setOpen("requestPersonalAffection"),
    isHidden:
      product.productClassName !== "dataLeakContract" ||
      product.leadStatus < caseStatus.STATUS_DATA_COMPLETED ||
      product.leadStatus > caseStatus.STATUS_TRIAL,
  },
  {
    text: "Ausschreiben",
    icon: MonetizationOnOutlined,
    onClick: () => setOpen("paidTask"),
  },
  {
    text: "E-Mail neu",
    icon: MarkEmailReadOutlined,
    onClick: () => setOpen("resendEmail"),
    isHidden: product.customer.verification !== null,
  },
  {
    text: "Passwort neu",
    icon: PasswordOutlined,
    onClick: () => setOpen("resendEmail"),
    isHidden: product.customer.verification === null,
  },
  {
    text: "Vollmacht neu",
    icon: HistoryEduOutlined,
    onClick: () => setOpen("powerOfAttorney"),
    isHidden: !(
      (_.includes(["traffic", "divorce", "rentalContract"], product.productClassName) ||
        (product.productCategory === "contractLaw" && product.caseCategory !== "advisory")) &&
      product.stage >= getProductData(product.productClassName, "setBackCaseStage")
    ),
  },
  {
    text: "Pausieren",
    icon: PauseCircleOutlined,
    onClick: () => setOpen("pause"),
  },
  {
    text: "Status ändern",
    icon: NextPlanOutlined,
    onClick: () => setOpen("status"),
  },
  {
    text: "Fall schieben",
    icon: KeyboardTabOutlined,
    onClick: () => setOpen("stage"),
    isHidden: !isMobile || !getNextStage(product),
  },
  {
    text: "Zurücksetzen",
    icon: UndoOutlined,
    onClick: () => setOpen("resetContract"),
    isHidden: product?.caseCategory !== "advisory",
  },
  {
    text: "Termin buchen",
    icon: EventAvailableOutlined,
    href: buildCalendlyLink(product, true),
    isHidden: !_.startsWith(product.customer.email, "advocard.error"),
  },
  {
    text: "Zahlart ändern",
    icon: EuroOutlined,
    onClick: () => setOpen("privatePayer"),
    isHidden: !getProductData(product.productClassName, "paymentTypeSwitchData").getActionAvailable(product),
  },
  {
    text: "Gerichtstermin",
    icon: Event,
    onClick: () => setOpen("courtAppointment"),
    isHidden:
      (!_.includes(["divorce", "settlement", "traffic", "rentalContract"], product.productClassName) &&
        product.productCategory !== "contractLaw") ||
      (product.productClassName === "divorce" && product.stage < stagesDivorce.STAGE_SUBMITTED) ||
      (product.productClassName === "settlement" && product.stage < stagesSettlement.STAGE_SUBMITTED) ||
      (product.productClassName === "traffic" && product.stage < stagesTraffic.STAGE_AUTHORITY_ANSWERED) ||
      (product.productClassName === "rentalContract" && product.stage < stagesCase.STAGE_LAWSUIT) ||
      (product.productCategory === "contractLaw" && product.stage < stagesCase.STAGE_LAWSUIT),
    divider: !isMobile,
  },
  {
    text: "Löschen",
    icon: DeleteForeverOutlined,
    onClick: () => setOpen("delete"),
  },
];
