import { Box, Grid } from "@mui/material";
import { scrollToAnchor } from "../../theme/commonStyles";
import ContentBox from "../ContentBox/ContentBox";
import { FormContainer, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import ButtonLoading from "../Button/ButtonLoading";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import _ from "lodash";
import { userHasOneOfTheseRoles, userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import ChangePassword from "./ChangePassword";
import React, { useState } from "react";
import { apiPost } from "../../services/Api/apiCall";
import EmailChangeModal from "../EmailChangeModal/EmailChangeModal";
import { queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isEmail } from "../../services/validationRules";
import { DevTool } from "@hookform/devtools";

export default function PersonalData() {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    if (values.email !== currentUser.email) {
      const emailCheck = await apiPost("check_user_email", { email: _.toLower(values.email) });
      if (emailCheck.customerExists) {
        setIsLoading(false);
        alert(emailCheck.errorText || "Es existiert bereits ein Konto mit dieser E-Mail-Adresse");
        return;
      }
      setIsEmailModalOpen(true);
    }
    const personData: Record<string, any> = {
      gender: values.person.gender,
      givenName: values.person.givenName,
      familyName: values.person.familyName,
      nameAddition: values.person.nameAddition,
      telephone: values.person.telephone,
      titles: _.filter(values.person.titles, (titleData) => {
        return titleData && titleData.title && !["no_title", ""].includes(titleData.title);
      }),
    };
    await updateMutation.mutateAsync(
      {
        uri: "backoffice_people",
        id: currentUser.person.id,
        data: personData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.all("backoffice_users"));
          queryClient.invalidateQueries(queryKeys.all("current_user"));
        },
      }
    );
    setIsLoading(false);
  };

  const getInitialTitles = (titles: any) => {
    if (!titles) {
      return null;
    }

    if (titles.length < 1) {
      return [{ title: "no_title", product: "all", fullTitle: "Kein Titel" }];
    }

    return titles;
  };

  const formContext = useForm({
    defaultValues: {
      email: currentUser.email,
      person: {
        gender: currentUser.person.gender,
        givenName: currentUser.person.givenName,
        familyName: currentUser.person.familyName,
        nameAddition: currentUser.person.nameAddition,
        telephone: currentUser.person.telephone,
        titles: getInitialTitles(currentUser.person.titles),
      },
    },
  });

  const { isDirty } = formContext.formState;

  return (
    <>
      <Box sx={scrollToAnchor} id={"myData"} />
      <ContentBox headline={"Persönliche Daten"}>
        <FormContainer formContext={formContext} onSuccess={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SelectElement
                name={"person.gender"}
                label={"Anrede"}
                options={[
                  { id: "male", label: "Herr" },
                  { id: "female", label: "Frau" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldElement
                name={"person.givenName"}
                label={"Vorname"}
                validation={{
                  required: "Vorname ist ein Pflichtfeld",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldElement
                name={"person.familyName"}
                label={"Nachname"}
                validation={{
                  required: "Nachname ist ein Pflichtfeld",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldElement name={"person.nameAddition"} label={"Namenszusatz"} />
            </Grid>
            {userHasRole(currentUser, Roles.external) && (
              <Grid item xs={12} md={4}>
                <TextFieldElement
                  name={"person.telephone"}
                  label={"Telefon"}
                  validation={{
                    required: "Telefon ist ein Pflichtfeld",
                    pattern: {
                      value: /^0?[1-9]\d+$/,
                      message:
                        "Die Telefonnummer darf keine Sonderzeichen enthalten oder mehrere führende Nullen haben.",
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <TextFieldElement
                name={"email"}
                label={"E-Mail"}
                validation={{
                  required: "E-Mail ist ein Pflichtfeld",
                  validate: { isEmail: (value: string) => isEmail(value) || "Dies ist keine gültige E-Mail Adresse" },
                }}
                disabled={!userHasRole(currentUser, Roles.external)}
              />
            </Grid>
            <Grid item xs={12}>
              {userHasOneOfTheseRoles(currentUser, [Roles.lawyer]) && <LawyerTitles />}
              {userHasOneOfTheseRoles(currentUser, [Roles.accountmanager]) && <AccountManagerTitles />}
            </Grid>
            <Grid item xs={12}>
              <ButtonLoading
                isLoading={isLoading}
                sx={{ width: "200px", margin: "0 auto", display: "block" }}
                variant={"contained"}
                disabled={!isDirty}
                type={"submit"}
              >
                Speichern
              </ButtonLoading>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: "2rem" }}>
            <ChangePassword />
          </Box>
          <EmailChangeModal setIsEmailModalOpen={setIsEmailModalOpen} isEmailModalOpen={isEmailModalOpen} />
        </FormContainer>
        <DevTool control={formContext.control} />
      </ContentBox>
    </>
  );
}

const LawyerTitles = () => {
  const currentUser = useCurrentUser();
  return (
    <>
      <SelectElement
        name={"person.titles.0.title"}
        label={"Titel"}
        options={[
          {
            id: currentUser.person.gender === "male" ? "Rechtsanwalt" : "Rechtsanwältin",
            label: currentUser.person.gender === "male" ? "Rechtsanwalt" : "Rechtsanwältin",
          },
          {
            id: currentUser.person.gender === "male" ? "Assessor" : "Assessorin",
            label: currentUser.person.gender === "male" ? "Assessor" : "Assessorin",
          },
        ]}
      />
      {_.map(_.slice(currentUser.person.titles, 1), (titleData, index) => {
        return (
          <SelectElement
            key={index + 1}
            label={"Fachanwaltstitel"}
            name={`person.titles.${index + 1}.title`}
            options={getSpecialListLawyerTitleOptions()}
          />
        );
      })}
      <SelectElement
        key={currentUser.person.titles!.length}
        label={"Weiterer Fachanwaltstitel"}
        name={`person.titles.${currentUser.person.titles!.length}.title`}
        options={getSpecialListLawyerTitleOptions()}
      />
    </>
  );
};

const AccountManagerTitles = () => {
  const currentUser = useCurrentUser();

  if (!currentUser.person.titles) {
    return null;
  }

  return (
    <SelectElement
      name={"person.titles.0.title"}
      label={"Titel"}
      options={[
        {
          id: "no_title",
          label: "Kein Titel",
        },
        {
          id: currentUser.person.gender === "male" ? "Rechtsanwaltsfachangestellter" : "Rechtsanwaltsfachangestellte",
          label:
            currentUser.person.gender === "male" ? "Rechtsanwaltsfachangestellter" : "Rechtsanwaltsfachangestellte",
        },
        {
          id: currentUser.person.gender === "male" ? "Rechtsfachwirt" : "Rechtsfachwirtin",
          label: currentUser.person.gender === "male" ? "Rechtsfachwirt" : "Rechtsfachwirtin",
        },
        {
          id: currentUser.person.gender === "male" ? "Auszubildender" : "Auszubildende",
          label: currentUser.person.gender === "male" ? "Auszubildender" : "Auszubildende",
        },
      ]}
    />
  );
};

const getSpecialListLawyerTitleOptions = () => {
  const specialistLawyerTitles = [
    "Familienrecht",
    "Verkehrsrecht",
    "Arbeitsrecht",
    "Erbrecht",
    "Miet- und Wohnungseigentumsrecht",
    "Verwaltungsrecht",
    "Steuerrecht",
    "Sozialrecht",
    "Strafrecht",
    "Insolvenz- und Sanierungsrecht",
    "Versicherungsrecht",
    "Medizinrecht",
    "Bau- und Architektenrecht",
    "Transport- und Speditionsrecht",
    "Gewerblicher Rechtsschutz",
    "Handels- und Gesellschaftsrecht",
    "Urheber- und Medienrecht",
    "Informationstechnologierecht",
    "Bank- und Kapitalmarktrecht",
    "Agrarrecht",
    "Internationales Wirtschaftsrecht",
    "Vergaberecht",
    "Migrationsrecht",
    "Sportrecht",
  ];

  return [
    { id: "", label: "Titel entfernen" },
    ...specialistLawyerTitles.map((specialistLawyerTitle) => ({
      id: specialistLawyerTitle,
      label: specialistLawyerTitle,
    })),
  ];
};
