import ApiClient from "../ApiClient";
import _ from "lodash";
import {
  ArticleOutlined,
  AudioFileOutlined,
  InsertDriveFileOutlined,
  InsertPhotoOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import React from "react";

export const previewCaseFile = async ({ product, documentType, documentIdentifier, params = {} }) => {
  const previewResponse = await ApiClient.post("files/generate/" + product.productClassName + "/" + product.id, {
    body: JSON.stringify(
      _.merge({}, params, { isPreview: true, documentType: documentType, documentIdentifier: documentIdentifier })
    ),
  });
  return previewResponse.base64Content;
};

export const mimeTypes = {
  image: ["image/jpeg", "image/png"], // removed "image/tiff" because only ie and safari support opening that format
  pdf: ["application/pdf"],
  video: ["video/x-msvideo", "video/mp4", "video/mpeg", "video/webm"],
  audio: ["audio/mpeg", "audio/ogg"],
};

export const getIconByMimeType = (mimeType) => {
  switch (true) {
    case _.includes(mimeTypes.pdf, mimeType):
      return <ArticleOutlined />;
    case _.includes(mimeTypes.image, mimeType):
      return <InsertPhotoOutlined />;
    case _.includes(mimeTypes.video, mimeType):
      return <VideoFileOutlined />;
    case _.includes(mimeTypes.audio, mimeType):
      return <AudioFileOutlined />;
    default:
      return <InsertDriveFileOutlined />;
  }
};

export const splitFileNameAndExtension = (fileName) => {
  let splitFileName = _.split(fileName, ".");
  const fileExtension = splitFileName.pop();
  return {
    extension: fileExtension,
    fileName: _.join(splitFileName, "."),
  };
};

export const createNoteAsFile = async (data) => {
  const generateNoteAsFile = await ApiClient.post("files/generate/" + data.productClassName + "/" + data.productId, {
    body: JSON.stringify({
      documentType: "note",
      documentIdentifier: data.documentIdentifier,
      showInGallery: "false",
      electronicFileFolderPath: data.electronicFileFolderPath,
      documentContent: data.note,
      createdBy: data.createdBy,
    }),
  });

  return generateNoteAsFile && generateNoteAsFile.status === "ok"
    ? "Dokument wurde erfolgreich erstellt"
    : "Dokument konnte nicht erstellt werden";
};
