import { AbstractCase } from "../../../../types/AbstractCase";
import { FormElementPropsWithoutProduct } from "../../../../components/Case/CaseForm/FormElement";

type AddressFieldsProps = {
  path: string;
  translationPath?: string;
  isDisabled?: ({ product, values }: { product: AbstractCase; values: AbstractCase }) => boolean;
  isHidden?: ({ product, values }: { product: AbstractCase; values: AbstractCase }) => boolean;
  hidePostOfficeBoxFields?: ({ product, values }: { product: AbstractCase; values: AbstractCase }) => boolean;
  hideAddressAddition?: boolean;
};
export default function addressFields({
  path,
  translationPath = "",
  isDisabled = () => false,
  isHidden = () => false,
  hidePostOfficeBoxFields = () => true,
  hideAddressAddition = true,
}: AddressFieldsProps) {
  translationPath = translationPath !== "" ? translationPath : path;
  const formElementProps: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorTextField",
      path: `${path}.streetAddress`,
      translationPath: `${translationPath}.streetAddress`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postalCode`,
      translationPath: `${translationPath}.postalCode`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.addressLocality`,
      translationPath: `${translationPath}.addressLocality`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.addressAddition`,
      translationPath: `${translationPath}.addressAddition`,
      isDisabled: isDisabled,
      isHidden: ({ product, values }: { product: AbstractCase; values: AbstractCase }) =>
        hideAddressAddition || isHidden({ product, values }),
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postOfficeBoxNumber`,
      translationPath: `${translationPath}.postOfficeBoxNumber`,
      isDisabled: isDisabled,
      isHidden: ({ product, values }: { product: AbstractCase; values: AbstractCase }) =>
        hidePostOfficeBoxFields({ product, values }) || isHidden({ product, values }),
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postOfficeBoxPostalCode`,
      translationPath: `${translationPath}.postOfficeBoxPostalCode`,
      isDisabled: isDisabled,
      isHidden: ({ product, values }: { product: AbstractCase; values: AbstractCase }) =>
        hidePostOfficeBoxFields({ product, values }) || isHidden({ product, values }),
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postOfficeBoxAddressLocality`,
      translationPath: `${translationPath}.postOfficeBoxAddressLocality`,
      isDisabled: isDisabled,
      isHidden: ({ product, values }: { product: AbstractCase; values: AbstractCase }) =>
        hidePostOfficeBoxFields({ product, values }) || isHidden({ product, values }),
    },
  ];
  return formElementProps;
}
