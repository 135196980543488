import personFields from "./personFields";
import {caseReferenceNumberIsUnique, insurancePickerValidator, textFieldDefault} from "../../../validationRules";

export default function basicInsuranceFields({ path, product, isHidden = () => false }) {
  return [
    {
      type: "InsurancePicker",
      path: `${path}.insurance`,
      isHidden: isHidden,
      validators: insurancePickerValidator(product),
    },
    {
      type: "ValidatorTextField",
      path: `${path}.caseReferenceNumber`,
      isHidden: isHidden,
      validators: [
        ...textFieldDefault,
        {
          validator: caseReferenceNumberIsUnique,
          params: [product.insurance.caseReferenceNumber],
          message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
        },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `${path}.insurancePolicyNumber`,
      isHidden: isHidden,
    },
    {
      type: "ValidatorSelect",
      path: `${path}.policyHolderType`,
      isHidden: isHidden,
      options: () => [
        {value: "me"},
        {value: "familyMember"},
      ],
    },
    ...personFields({
      path: `${path}.policyHolder`,
      isHidden: ({ product, values }) =>
        isHidden({ product }) || !product.insurance.policyHolder || values[product.productClassName].insurance.policyHolderType !== "familyMember",
    }),
  ];
}
