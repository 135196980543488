import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import validatorStyle from "./validatorStyle";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import DateChangeButtonsToolbar from "./DateChangeButtonsToolbar";
import { ValidatorType } from "../../types/ValidatorType";
import { Moment } from "moment";
import { UseForm } from "../../types/UseForm";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker/DatePicker";

type ValidatorDateFieldProps = {
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<string>;
  label: string;
  format?: string;
  name: string;
  helperText?: string;
  isHighlighted?: boolean;
  withSkipWeeksToolbar?: boolean;
  error?: boolean;
} & Pick<UseForm, "registerValidators" | "handleBlur"> &
  Omit<DatePickerProps<Moment, Moment>, "renderInput">;

function ValidatorDateField({
  name,
  value,
  helperText,
  registerValidators,
  validators,
  dependentValidationFields = [],
  isHighlighted = false,
  handleBlur,
  withSkipWeeksToolbar = false,
  error = false,
  format = "DD.MM.YYYY",
  ...rest
}: ValidatorDateFieldProps) {
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, validators, dependentValidationFields, name]);

  return (
    <FormControl fullWidth={true} sx={isHighlighted && !value ? validatorStyle.mandatory : undefined} error={error}>
      <DatePicker
        {...rest}
        value={value || null}
        renderInput={(props) => (
          <TextField
            {...props}
            inputProps={{
              ...props.inputProps,
              placeholder: "tt.mm.jjjj",
            }}
          />
        )}
        mask={"__.__.____"}
        onAccept={(date) => {
          if (!handleBlur || !date) {
            return;
          }
          handleBlur({ target: { name: name, value: date } });
        }}
        ToolbarComponent={withSkipWeeksToolbar ? DateChangeButtonsToolbar : undefined}
        showToolbar={withSkipWeeksToolbar}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default ValidatorDateField;
