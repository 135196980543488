import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import personTranslations from "./personTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import contractualPartnerLegalEntityTranslations from "./contractualPartnerLegalEntityTranslations";
import debtCollectionAgencyTranslations from "./debtCollectionAgencyTranslations";
import { objectivesLawFirmValues } from "./objectivesLawFirmTranslations";
import contactTranslations from "./contactTranslations";
import addressTranslations from "./addressTranslations";

const rentalContractTranslations = {
  label: "Mietvertrag",
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  reasonForDispute: {
    label: "Problem der Mandantschaft",
    values: {
      other: "Anderes Anliegen",
      deposit: "Kaution",
      rentIncrease: "Mieterhöhung",
      utilitiesStatement: "Nebenkostenabrechnung",
      illegalContractClause: "Widerrechtliche Vertragsklausel",
      landlordHasCancelled: "Kündigung",
      housingDeficiencies: "Mietmängel",
    },
  },
  housingDeficienciesDescriptionClient: {
    label: "Mängelbeschreibung Mandantschaft",
  },
  housingDeficienciesDescriptionLawFirm: {
    label: "Mängelbeschreibung Kanzlei",
  },
  previousBasicRent: {
    label: "Höhe vereinbarte Kaltmiete",
  },
  previousRentIncludingHeating: {
    label: "Höhe vereinbarte Warmmiete",
  },
  futureBasicRent: {
    label: "Höhe der neuen Kaltmiete",
  },
  futureRentIncludingHeating: {
    label: "Höhe der neuen Warmmiete",
  },
  cancellationReceivedDate: {
    label: "Eingang der Kündigung",
  },
  rentalContractStartDate: {
    label: "Start Mietverhältnis",
  },
  rentalContractEndDate: {
    label: "Ende Mietverhältnis",
  },
  depositAmount: {
    label: "Höhe der Kaution",
  },
  customer: customerTranslations,
  ...basicCaseTranslations,

  ...litigationCaseTranslations,
  ...abstractCaseTranslations,
  additionalClaimText: {
    label: "Zusatztext Forderung",
  },
  additionalIssueDescriptionText: {
    label: "Zusatztext Schilderung Sachverhalt",
  },
  buyerPaidDate: {
    label: "Zahlungsdatum Käufer",
  },
  buyerPaidStatus: {
    label: "Bezahlstatus Käufer",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  clientLegalRepresentative: personTranslations,
  responsibleProsecution: prosecutionTranslations,
  prosecutionReference: {
    label: "Aktenzeichen Staatsanwaltschaft",
  },
  contractNumber: {
    label: "Vertragsnummer",
  },
  automaticallyGenerateExtrajudicialLetter: {
    label: "Automatisches Schreiben verwenden?",
  },
  contractDate: {
    label: "Vertragsabschluss am",
  },
  contractualPartnerType: {
    label: "Vermieter ist",
    values: {
      contractualPartnerLegalEntity: "Unternehmen",
      contractualPartnerPerson: "Privatperson",
    },
  },
  contractualPartnerPerson: personTranslations,
  contractualPartnerLegalEntity: {
    label: "Gegenseite",
    ...contractualPartnerLegalEntityTranslations,
  },
  debtCollectionAgencyReference: {
    label: "Aktenzeichen",
  },
  debtCollectionAgency: {
    label: "Inkasso",
    ...debtCollectionAgencyTranslations,
  },
  propertyManager: {
    label: "Hausverwaltung",
    legalName: {
      label: "Name",
    },
    ...contactTranslations,
    address: addressTranslations,
  },
  propertyManagerReference: {
    label: "Aktenzeichen",
  },
  defenseProcessStatus: {
    label: "Status Forderung gegen Mandantschaft",
    values: {
      none: "Noch nichts erhalten",
      reminder: "Zahlungserinnerung / Mahnung",
      debtCollection: "Inkasso eingeschaltet",
      paymentOrder: "Mahnbescheid erhalten",
      enforcementOrder: "Vollstreckungsbescheid erhalten",
      lawsuit: "Klage erhalten",
    },
  },
  goodsOrServiceProvidedStatus: {
    label: "Status Leistungserbringung/Lieferung",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  claimedDateClient: {
    label: "Datum Forderungsschreiben Mandant",
  },
  claimedDeadlineClient: {
    label: "Forderungsfrist Mandant",
  },
  clientHasAlreadyClaimed: {
    label: "Mandant hat bereits eingefordert",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  objectiveLawFirm: {
    label: "Zielsetzung Kanzlei",
    values: {
      ...objectivesLawFirmValues,
    },
  },
  objectiveLawFirmSecondary: {
    label: "Sekundäre Zielsetzung Kanzlei",
    values: {
      ...objectivesLawFirmValues,
    },
  },
  objectiveLawFirmTertiary: {
    label: "Tertiäre Zielsetzung Kanzlei",
    values: {
      ...objectivesLawFirmValues,
    },
  },
  paymentOrderDate: {
    label: "Datum des Mahnbescheids",
  },
  enforcementOrderDate: {
    label: "Datum des Vollstreckungsbescheids",
  },
  claimedAmountClient: {
    label: "Forderungsbetrag Mandant",
  },
  overdueFees: {
    label: "Mahngebühren",
  },
  claimedAmountLawFirm: {
    label: "Forderungsbetrag Kanzlei",
  },
  extrajudicialCommunicationSent: {
    label: "Schreiben an Gegenseite am",
  },
  extrajudicialCommunicationDeadline: {
    label: "Gesetzte Frist für Gegenseite",
  },
  subjectOfDispute: {
    label: "Forderung",
    values: {
      money: "Geld",
      goods: "Ware oder Leistung",
      moneyAndGoods: "Geld und Leistung",
      other: "Sonstiges",
    },
    enforceClaim: {
      label: "Forderung Mandant",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        moneyAndGoods: "Geld und Leistung",
        other: "Sonstiges",
      },
    },
    defendClaim: {
      label: "Forderung Gegenseite",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        moneyAndGoods: "Geld und Leistung",
        other: "Sonstiges",
      },
    },
  },
  trialWanted: {
    label: "Gerichtsverfahren gewünscht",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
  extrajudicialReaction: {
    label: "Reaktion des Vermieters",
    values: {
      noReaction: "Keine Reaktion",
      fullyAgreed: "Forderung wird vollständig erfüllt/aufgegeben",
      partiallyAgreed: "Forderung wird teilweise erfüllt/aufgegeben",
      disagreed: "Forderung wird nicht erfüllt/aufrechterhalten",
    },
    enforceClaim: {
      label: "Reaktion des Vermieters",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird voll erfüllt",
        partiallyAgreed: "Forderung wird teilweise erfüllt",
        disagreed: "Forderung wird nicht erfüllt",
      },
    },
    defendClaim: {
      label: "Reaktion des Vermieters",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird vollständig aufgegeben",
        partiallyAgreed: "Forderung wird teilweise aufgegeben",
        disagreed: "Forderung wird aufrechterhalten",
      },
    },
  },
  extrajudicialRecommendation: {
    label: "Empfehlung weiteres Vorgehen",
    values: {
      agreementDone: "Einigung erzielt / Forderung erledigt",
      lawsuit: "Klage einreichen / Klage abwarten",
      insufficientEvidence: "Schlechte Beweislage",
      insufficientChancesOfSuccess: "Schlechte Erfolgsaussicht",
      costBenefitConsiderations: "Schlechtes Kosten-Nutzen-Verhältnis",
      anonymousOpponent: "Gegenseite unklar",
      opponentUnableToPay: "Gegenseite nicht solvent",
      individualText: "Individuelle Empfehlung",
    },
  },
  extrajudicialRecommendationText: {
    label: "Zusatztext Empfehlung",
  },
  finalClaimStatusClient: {
    label: "Stand Forderung aus Sicht Mandantschaft",
    values: {
      fullyAgreed: "Voll erledigt",
      partiallyAgreed: "Teilweise erledigt",
      notAgreed: "Nicht erledigt",
      noReaction: "Keine erledigt",
    },
    enforceClaim: {
      label: "Stand Forderung aus Sicht Mandantschaft",
      values: {
        fullyAgreed: "Voll erfüllt",
        partiallyAgreed: "Teilweise erfüllt",
        notAgreed: "Nicht erfüllt",
        noReaction: "Keine Reaktion",
      },
    },
    defendClaim: {
      label: "Stand Forderung aus Sicht Mandantschaft",
      values: {
        fullyAgreed: "Forderung durch Gegenseite aufgegeben",
        partiallyAgreed: "Forderung durch Gegenseite teilweise aufgegeben",
        notAgreed: "Forderung durch Gegenseite nicht aufgegeben",
        noReaction: "Keine Reaktion",
      },
    },
  },
  finalProcessFeedbackClient: {
    label: "Finale Entscheidung Mandantschaft",
    values: {
      agreement: "Fall abschließen",
      lawsuit: "Gerichtsverfahren",
    },
    enforceClaim: {
      label: "Finale Entscheidung Mandantschaft",
      values: {
        agreement: "Forderung erledigt/zurücknehmen",
        lawsuit: "Forderung einklagen",
      },
    },
    defendClaim: {
      label: "Finale Entscheidung Mandantschaft",
      values: {
        lawsuit: "Forderung nicht erfüllen und Klage abwarten",
        agreement: "Forderung erfüllen und Klage vermeiden",
      },
    },
  },
  extrajudicialReactionReceived: {
    label: "Reaktion Vermieter am",
  },
  timeBarredDate: {
    label: "Fall verjährt am",
  },
};

export default rentalContractTranslations;
