import jwtDecode from "jwt-decode";
import _ from "lodash";
import {Roles} from "../types/BackofficeUser";

export const loginToken = (token) => {
  localStorage.setItem("legalbirdServicesJWT", token);
};

export const logoutToken = () => {
  localStorage.clear();
  sessionStorage.clear();
}

export const loadToken = () => {
  return localStorage.getItem("legalbirdServicesJWT");
};

export const checkToken = (token, currentUser) => {
  if (!_.isString(token) || token.length === 0 || !currentUser) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    return (
      (decodedToken.username === currentUser.email || decodedToken.roles.includes(Roles.admin)) &&
      decodedToken.exp > Date.now() / 1000
    );
  } catch (err) {
    return false;
  }
};
