/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import * as stagesTraffic from "../../../Stages/StagesTraffic";
import { dateFieldDefault, textFieldDefault } from "../../../validationRules";
import { formValue } from "../../../formServiceFunctions";
import { trueFalseDefaultOptions, yesNoDefaultOptions, yesNoUnknownDefaultOptions } from "../formDefinitionFunctions";
import { AbstractCase } from "../../../../types/AbstractCase";

const decisionType = ({ product }: { product: AbstractCase }) => {
  return product.authorityDecision === "revoked" ||
    product.authorityDecision === "changed" ||
    product.appealRevoked !== null
    ? "extrajudicial"
    : "court";
};

const opinionRequiredFields = [
  {
    type: "ValidatorSelect",
    path: "traffic.opinionRequired",
    options: ({ product }: { product: AbstractCase }) =>
      trueFalseDefaultOptions(`${product.productClassName}.opinionRequired`),
  },
  {
    type: "ValidatorDateField",
    path: "traffic.opinionSubmitted",
    validators: dateFieldDefault,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.opinionRequired") === false,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.authorityAnswered",
    validators: dateFieldDefault,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.opinionRequired") !== false,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.authorityDecision",
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.opinionRequired") !== false,
  },
];

const incompleteAndReadyData = [
  {
    type: "ValidatorTextField",
    path: "traffic.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.legalExpenseInsurance",
    options: () => trueFalseDefaultOptions("traffic.legalExpenseInsurance"),
  },
  {
    type: "ValidatorSelect",
    path: "traffic.paymentType",
    options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "private" }],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusation",
    isDisabled: () => true,
    options: () => [
      { value: "__null__" },
      { value: "speeding" },
      { value: "red_light" },
      { value: "distance" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusationOther",
    isDisabled: () => true,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "other",
    options: () => [
      { value: "__null__" },
      { value: "mobile_phone" },
      { value: "drugs" },
      { value: "parking" },
      { value: "other" },
    ],
  },
  /* ============== RED LIGHT =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.roadUsersEndangered",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "red_light",
    options: () => yesNoDefaultOptions("traffic.roadUsersEndangered"),
  },
  {
    type: "ValidatorSelect",
    path: "traffic.damage",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "red_light",
    options: () => yesNoUnknownDefaultOptions("traffic.damage"),
  },
  {
    type: "ValidatorSelect",
    path: "traffic.redLight",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "red_light",
    options: () => [{ value: "__null__" }, { value: "under_1_sec" }, { value: "1_sec_or_more" }, { value: "unknown" }],
  },
  /* ============== DISTANCE =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.speed",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "distance",
    options: () => [
      { value: "__null__" },
      { value: "<81" },
      { value: "81-100" },
      { value: "101-130" },
      { value: ">130" },
      { value: "unknown" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.distance",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "distance",
    options: () => [
      { value: "__null__" },
      { value: "5/10" },
      { value: "4/10" },
      { value: "3/10" },
      { value: "2/10" },
      { value: "1/10" },
      { value: "unknown" },
    ],
  },
  /* ============== SPEEDING =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.locality",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "speeding",
    options: () => [{ value: "__null__" }, { value: "in_town" }, { value: "out_of_town" }, { value: "unknown" }],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.speeding",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "speeding",
    options: () => [
      { value: "__null__" },
      { value: "0-20" },
      { value: "21-30" },
      { value: "31-40" },
      { value: ">40" },
      { value: "41-50" },
      { value: "51-60" },
      { value: "61-70" },
      { value: ">70" },
      { value: "unknown" },
    ],
  },
  /* ============== MOBILE PHONE =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.vehicle",
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusationOther") !== "mobile_phone",
    options: () => [
      { value: "__null__" },
      { value: "car" },
      { value: "bike" },
      { value: "motorcycle" },
      { value: "truck" },
      { value: "bus" },
    ],
  },
  /* ============== OPTIONALS END ===================== */
  {
    type: "ValidatorSelect",
    path: "traffic.authorityNotification",
    options: () => [
      { value: "__null__" },
      { value: "hearing_report" },
      { value: "witness_report" },
      { value: "fine_notice" },
      { value: "no_notice" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: "traffic.fineNoticeReceivedDate",
    validators: dateFieldDefault,
  },
];
const checkedData = [
  {
    type: "ValidatorSelect",
    path: "traffic.accusation",
    isDisabled: () => true,
    options: () => [
      { value: "__null__" },
      { value: "speeding" },
      { value: "red_light" },
      { value: "distance" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusationOther",
    isDisabled: () => true,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "other",
    options: () => [
      { value: "__null__" },
      { value: "mobile_phone" },
      { value: "drugs" },
      { value: "parking" },
      { value: "other" },
    ],
  },
];

const basicAccusationData = [
  {
    type: "ValidatorTextField",
    path: "traffic.fineNoticeReference",
    isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
    validators: textFieldDefault,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusation",
    isDisabled: () => true,
    options: () => [
      { value: "__null__" },
      { value: "speeding" },
      { value: "red_light" },
      { value: "distance" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusationOther",
    isDisabled: () => true,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "other",
    options: () => [
      { value: "__null__" },
      { value: "mobile_phone" },
      { value: "drugs" },
      { value: "parking" },
      { value: "other" },
    ],
  },
];
const trialData = [
  ...basicAccusationData,
  {
    type: "ValidatorDateField",
    path: "traffic.authorityAnswered",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.authorityDecision",
    isDisabled: () => true,
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.courtNotice",
    isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_TRIAL_DATE_SET,
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "no_trial" }, { value: "trial" }],
  },
  {
    type: "ValidatorTextField",
    path: "traffic.courtReference",
    validators: textFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.courtDecisionDate",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.customerAppealRevocationApproved",
    validators: dateFieldDefault,
    additionalProps: {
      helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
    },
    alert: {
      onInitial:
        "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
    },
  },
  {
    type: "ValidatorDateField",
    path: "traffic.appealRevoked",
    validators: dateFieldDefault,
  },
];
const trialDoneData = [
  ...basicAccusationData,
  {
    type: "ValidatorTextField",
    path: "traffic.courtReference",
    isHidden: ({ product }: { product: AbstractCase }) => decisionType({ product }) !== "court",
    validators: textFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.courtDecisionDate",
    isHidden: ({ product }: { product: AbstractCase }) => decisionType({ product }) !== "court",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.authorityDecision",
    isHidden: ({ product }: { product: AbstractCase }) => decisionType({ product }) !== "extrajudicial",
    isDisabled: () => true,
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
  },
  {
    type: "ValidatorDateField",
    path: "traffic.customerAppealRevocationApproved",
    isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_DECISION_MADE,
    validators: dateFieldDefault,
    additionalProps: {
      helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
    },
    alert: {
      onInitial:
        "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
    },
  },
  {
    type: "ValidatorDateField",
    path: "traffic.appealRevoked",
    isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_DECISION_MADE,
    validators: dateFieldDefault,
  },
];

export const stageFormDefinition = {
  [stagesTraffic.STAGE_INCOMPLETE]: [...incompleteAndReadyData],
  [stagesTraffic.STAGE_READY]: [
    ...incompleteAndReadyData,
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      isHidden: ({ product }: { product: AbstractCase }) => product.paymentType !== "private",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_CHECKED]: checkedData,
  [stagesTraffic.STAGE_AUTHORIZED]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      isHidden: ({ product }: { product: AbstractCase }) => product.paymentType !== "private",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST]: [
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_ACCESS_TO_RECORDS]: [
    ...checkedData,
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.insuranceCoverageDate",
      isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.paymentType") !== "insurance",
      validators: dateFieldDefault,
    },
    ...opinionRequiredFields,
    {
      type: "ValidatorDateField",
      path: "traffic.accessToRecordRequested",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_EXTRAJUDICIAL_PAID]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.accessToRecordRequested",
      isDisabled: () => true,
    },
    ...opinionRequiredFields,
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_OPINION_SUBMITTED]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.opinionSubmitted",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.authorityAnswered",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: "traffic.authorityDecision",
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    },
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_AUTHORITY_ANSWERED]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.opinionSubmitted",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.authorityAnswered",
      isDisabled: () => true,
    },
    {
      type: "ValidatorSelect",
      path: "traffic.authorityDecision",
      isDisabled: () => true,
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    },
    {
      type: "ValidatorSelect",
      path: "traffic.trialWanted",
      isHidden: ({ product }: { product: AbstractCase }) => product.authorityDecision === "revoked",
      options: () => yesNoDefaultOptions("traffic.trialWanted"),
    },
    {
      type: "ValidatorSelect",
      path: "traffic.courtNotice",
      isHidden: ({ product }: { product: AbstractCase }) => product.authorityDecision === "revoked",
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "no_trial" }, { value: "trial" }],
    },
    {
      type: "ValidatorTextField",
      path: "traffic.courtReference",
      isHidden: ({ product }: { product: AbstractCase }) => product.authorityDecision === "revoked",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "traffic.responsibleCourtAddress.postalCode",
      isHidden: ({ product }: { product: AbstractCase }) => product.authorityDecision === "revoked",
      validators: textFieldDefault,
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_TRIAL]: trialData,
  [stagesTraffic.STAGE_TRIAL_DATE_SET]: trialDoneData,
  [stagesTraffic.STAGE_TRIAL_PAID]: trialDoneData,
  [stagesTraffic.STAGE_DECISION_MADE]: trialDoneData,
  [stagesTraffic.STAGE_COMPLETED]: [
    {
      type: "ValidatorSelect",
      path: "traffic.accusation",
      isDisabled: () => true,
      options: () => [
        { value: "__null__" },
        { value: "speeding" },
        { value: "red_light" },
        { value: "distance" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: "traffic.accusationOther",
      isDisabled: () => true,
      isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "traffic.accusation") !== "other",
      options: () => [
        { value: "__null__" },
        { value: "mobile_phone" },
        { value: "drugs" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => !product.customerAppealRevocationApproved,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => !product.appealRevoked,
    },
    {
      type: "ValidatorSelect",
      path: "traffic.authorityDecision",
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => !product.authorityDecision,
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    },
    {
      type: "ValidatorDateField",
      path: "traffic.courtDecisionDate",
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => !product.courtDecisionDate,
    },
    {
      type: "ValidatorTextField",
      path: "traffic.fineNoticeReference",
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "traffic.courtReference",
      isHidden: ({ product }: { product: AbstractCase }) => !product.courtReference,
    },
  ],
};
