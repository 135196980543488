/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import * as stagesDivorce from "../../../Stages/StagesDivorce";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import { formValue } from "../../../formServiceFunctions";
import {
  beaSafeIdFieldDefault,
  dateFieldDefault,
  emailDefault,
  faxNumberDefault,
  numberFieldDefault,
  postalCodeDefault,
  textFieldDefault,
} from "../../../validationRules";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import _ from "lodash";
import nationalities from "../../../nationalities";
import { getCourtAppointmentFields } from "../contract/fullFormDefinition";
import { allDataPageCourtFields } from "../allDataPageCourtFields";
import { hasActiveCourtAppointment } from "../../ProductService";
import personFields from "../modules/personFields";
import addressFields from "../modules/addressFields";
import basicInsuranceFields from "../modules/basicInsuranceFields";
import { AbstractCase } from "../../../../types/AbstractCase";

const childrenFields = () => {
  let fields = [];

  for (let i = 0; i < 6; i++) {
    fields.push(
      {
        type: "ValidatorTextField",
        path: "divorce.children[" + i + "].name",
        validators: textFieldDefault,
        isHidden: ({ product }: { product: AbstractCase }) => product.numberChildren < i + 1,
        isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
      },
      {
        type: "ValidatorSelect",
        path: "divorce.children[" + i + "].livesWith",
        options: () => [{ value: "petitioner" }, { value: "partner" }],
        isHidden: ({ product }: { product: AbstractCase }) => product.numberChildren < i + 1,
        isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
      }
    );
  }
  return fields;
};

const partner = {
  label: "Gegenseite - Ehepartner",
  elements: [
    {
      type: "ValidatorSelect",
      path: "divorce.partner.gender",
      isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
      options: () => [{ value: "male" }, { value: "female" }],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.givenName",
      isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
      validators: [...textFieldDefault],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.familyName",
      isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
      validators: [...textFieldDefault],
    },
    {
      type: "ValidatorSelect",
      path: "divorce.partner.nationality",
      isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
      options: () =>
        _.map(nationalities, (nationality) => {
          return {
            value: nationality.label,
            label: nationality.label,
          };
        }),
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.residenceAddress.streetAddress",
      validators: [...textFieldDefault],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.residenceAddress.postalCode",
      validators: [...textFieldDefault, postalCodeDefault],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.residenceAddress.addressLocality",
      validators: [...textFieldDefault],
    },
  ],
};

const opponentLawyer = {
  label: "Anwalt Gegenseite",
  elements: [
    {
      type: "BeaLawyerImportButton",
      path: "pathPropNotUsedButRequired",
      additionalProps: {
        fullWidth: true,
      },
    },
    ...personFields({ path: `divorce.opponentLawyer` }),
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.name`,
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.telephone`,
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.beaSafeId`,
      validators: beaSafeIdFieldDefault,
    },
    ...addressFields({ path: `divorce.opponentLawFirm.address` }),
    {
      type: "ValidatorTextField",
      path: `divorce.referenceOpponentLawyer`,
    },
  ],
};

export const fullFormDefinition = (product: AbstractCase) => ({
  product: {
    sections: [
      {
        label: "Angaben Antragsteller",
        elements: [
          {
            type: "ValidatorSelect",
            path: "divorce.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }, { value: "diverse" }],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "divorce.petitioner.nationality",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            options: () =>
              _.map(nationalities, (nationality) => {
                return {
                  value: nationality.label,
                  label: nationality.label,
                };
              }),
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.residenceAddress.streetAddress",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.residenceAddress.addressLocality",
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Letzte gemeinsame Adresse",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.commonAddress.streetAddress",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.commonAddress.postalCode",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.commonAddress.addressLocality",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Ehe und Scheidung",
        elements: [
          {
            type: "ValidatorDateField",
            path: "divorce.dateMarriage",
            validators: [...dateFieldDefault],
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.cityMarriage",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "divorce.numberChildren",
            options: () => [
              { value: 0 },
              { value: 1 },
              { value: 2 },
              { value: 3 },
              { value: 4 },
              { value: 5 },
              { value: 6 },
            ],
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) > stagesDivorce.STAGE_INCOMPLETE,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.statusDivorce",
            options: () => [
              { value: "__null__" },
              { value: "mutuallyAgreed" },
              { value: "unsure" },
              { value: "debatable" },
            ],
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.dateSeparation",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "divorce.movedOut",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            options: () => [{ value: "petitioner" }, { value: "partner" }, { value: "nobody" }, { value: "both" }],
          },
          {
            type: "ValidatorDateField",
            path: "divorce.dateMovedOut",
            validators: dateFieldDefault,
            isDisabled: ({ values, product }: { values: AbstractCase; product: AbstractCase }) =>
              formValue(values, "divorce.movedOut") === "nobody" || (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
        ],
      },
      {
        label: "Finanzen",
        elements: [
          {
            type: "ValidatorNumberField",
            path: "divorce.netIncomePetitioner",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            fieldType: "money",
            numberType: "int",
          },
          {
            type: "ValidatorNumberField",
            path: "divorce.netIncomePartner",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            fieldType: "money",
            numberType: "int",
          },
          {
            type: "ValidatorSelect",
            path: "divorce.pensionsQuestions",
            options: () => trueFalseDefaultOptions("divorce.pensionsQuestions"),
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.pensionsExcluded",
            options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.shortMarriage",
            isDisabled: () => true,
            options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
          },
          {
            type: "ValidatorTextField",
            path: "divorce.numberPensions",
            validators: [...numberFieldDefault],
            isDisabled: ({ values, product }: { values: AbstractCase; product: AbstractCase }) =>
              formValue(values, "divorce.pensionsExcluded") === true ||
              (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.numberPensionsPartner",
            validators: [...numberFieldDefault],
            isDisabled: ({ values, product }: { values: AbstractCase; product: AbstractCase }) =>
              formValue(values, "divorce.pensionsExcluded") === true ||
              (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.hasProcessCostSupport",
            options: () => trueFalseDefaultOptions("divorce.hasProcessCostSupport"),
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
        ],
      },
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.personalTextMessage",
            isDisabled: () => true,
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Gemeinsame Kinder",
        isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.numberChildren") === "0",
        elements: childrenFields(),
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.reference",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.powerOfAttorney",
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.applicationSubmittedDate",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.referenceCourt",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.marriageRegistrationNumber",
            validators: textFieldDefault,
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.processCostSupportVerified",
            validators: dateFieldDefault,
            isHidden: ({ values }: { values: AbstractCase }) =>
              formValue(values, "divorce.hasProcessCostSupport") === false,
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
          },
        ],
      },
      {
        label: "Ratenzahlung",
        isHidden: ({ product }: { product: AbstractCase }) => product.hasProcessCostSupport,
        elements: [
          {
            type: "ValidatorSelect",
            path: "divorce.installment",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesDivorce.STAGE_READY,
            options: () => trueFalseDefaultOptions("divorce.hasProcessCostSupport"),
          },
          {
            type: "ValidatorSelect",
            path: "divorce.installmentRateOverdue",
            options: () => trueFalseDefaultOptions("divorce.installmentRateOverdue"),
            isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.installment") !== true,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.installmentRateOverdueSince",
            validators: dateFieldDefault,
            isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.installment") !== true,
          },
        ],
      },
      {
        label: "Gerichtsgebühr",
        elements: [
          {
            type: "ValidatorDateField",
            path: "divorce.courtFeeInvoiceReceivedDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }: { product: AbstractCase }) => product.stage !== stagesDivorce.STAGE_SUBMITTED,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.courtFeePaidDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }: { product: AbstractCase }) => product.stage !== stagesDivorce.STAGE_SUBMITTED,
          },
        ],
      },
      {
        label: "Zum Scheidungstermin",
        elements: [
          {
            type: "ValidatorNumberField",
            path: "divorce.valueInDisputeReal",
            fieldType: "money",
            numberType: "float",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              ![
                stagesDivorce.STAGE_HEARING_PAID,
                stagesDivorce.STAGE_DIVORCE_DONE,
                stagesDivorce.STAGE_DATE_SET,
              ].includes(product.stage || 0),
          },
        ],
      },
      {
        label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
        isHidden: ({ product }: { product: AbstractCase }) => product.courtAppointments.length === 0,
        elements: getCourtAppointmentFields(product),
      },
    ],
  },
  processParticipants: {
    sections: [
      partner,
      {
        label: "Versicherung",
        isHidden: ({ product }: { product: AbstractCase }) => !product.acquisitionPartner,
        elements: basicInsuranceFields({ path: "divorce.insurance", product: product }),
      },
      opponentLawyer,
    ],
  },
  additionalProcessParticipants: {
    sections: [
      {
        label: "Personen",
        elements: [
          {
            type: "ProcessParticipantsPeopleForm",
            path: `${product.productClassName}.relatedPeople`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Unternehmen",
        elements: [
          {
            type: "ProcessParticipantsOrganizationsForm",
            path: `${product.productClassName}.relatedOrganizations`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
    ],
    withoutSubmit: true,
  },
  court: {
    sections: [
      {
        elements: [
          {
            type: "CourtPicker",
            path: "divorce.responsibleCourt",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesDivorce.STAGE_CHECKED ||
              hasActiveCourtAppointment(product.courtAppointments),
            additionalProps: {
              fullWidth: true,
              courtTypes: ["Familiengericht", "Mahngericht"],
            },
          },
          ...allDataPageCourtFields("divorce", "responsibleCourt"),
        ],
      },
    ],
  },
});
