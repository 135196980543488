/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import * as stagesDivorce from "../../../Stages/StagesDivorce";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import { dateFieldDefault, textFieldDefault } from "../../../validationRules";
import { formValue } from "../../../formServiceFunctions";
import { AbstractCase } from "../../../../types/AbstractCase";

const checkedAndProcessPaidDisabledFields = [
  {
    type: "ValidatorSelect",
    path: "divorce.numberChildren",
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }],
    isDisabled: () => true,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateSeparation",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.movedOut",
    options: () => [
      { value: "__null__" },
      { value: "petitioner" },
      { value: "partner" },
      { value: "nobody" },
      { value: "both" },
    ],
    isDisabled: () => true,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateMovedOut",
    isDisabled: () => true,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.movedOut") === "nobody",
  },
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsExcluded",
    options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.shortMarriage",
    isDisabled: () => true,
    isHidden: ({ values, product }: { values: AbstractCase; product: AbstractCase }) =>
      formValue(values, "divorce.pensionsExcluded") === false ||
      (product.stage || 0) !== stagesDivorce.STAGE_PROCESS_PAID,
    options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
  },
];
const installmentData = [
  {
    type: "ValidatorSelect",
    path: "divorce.installmentRateOverdue",
    options: () => trueFalseDefaultOptions("divorce.installmentRateOverdue"),
    isHidden: ({ product }: { product: AbstractCase }) => !product.installment,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.installmentRateOverdueSince",
    validators: dateFieldDefault,
    isHidden: ({ product }: { product: AbstractCase }) => !product.installment,
  },
];

const submittedFields = [
  {
    type: "ValidatorDateField",
    path: "divorce.applicationSubmittedDate",
    isDisabled: () => true,
  },
  {
    type: "ValidatorTextField",
    path: "divorce.referenceCourt",
    validators: textFieldDefault,
  },
  ...installmentData,
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsExcluded",
    options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.shortMarriage",
    isDisabled: () => true,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.pensionsExcluded") === false,
    options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
  },
  {
    type: "ValidatorDateField",
    path: "divorce.courtFeeInvoiceReceivedDate",
    validators: dateFieldDefault,
    isHidden: ({ product }: { product: AbstractCase }) => product.hasProcessCostSupport,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.courtFeePaidDate",
    validators: dateFieldDefault,
    isHidden: ({ product }: { product: AbstractCase }) => product.hasProcessCostSupport,
  },
];

const incompleteAndReadyData = [
  {
    type: "ValidatorTextField",
    path: "divorce.marriageRegistrationNumber",
    validators: textFieldDefault,
    isHidden: ({ product }: { product: AbstractCase }) => (product.stage || 0) !== stagesDivorce.STAGE_READY,
  },
  {
    type: "ValidatorTextField",
    path: "divorce.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.hasProcessCostSupport",
    options: () => trueFalseDefaultOptions("divorce.hasProcessCostSupport"),
    isDisabled: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.installment") === true,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.processCostSupportVerified",
    validators: dateFieldDefault,
    isHidden: ({ values, product }: { values: AbstractCase; product: AbstractCase }) =>
      formValue(values, "divorce.hasProcessCostSupport") === false ||
      (product.stage || 0) !== stagesDivorce.STAGE_READY,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.installment",
    isDisabled: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.hasProcessCostSupport") === true,
    options: () => trueFalseDefaultOptions("divorce.hasProcessCostSupport"),
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateMarriage",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "divorce.numberChildren",
    isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) !== stagesDivorce.STAGE_INCOMPLETE,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }],
  },
  {
    type: "ValidatorSelect",
    path: "divorce.statusDivorce",
    options: () => [{ value: "__null__" }, { value: "mutuallyAgreed" }, { value: "unsure" }, { value: "debatable" }],
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateSeparation",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "divorce.movedOut",
    options: () => [
      { value: "__null__" },
      { value: "petitioner" },
      { value: "partner" },
      { value: "nobody" },
      { value: "both" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateMovedOut",
    validators: dateFieldDefault,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.movedOut") === "nobody",
  },
  {
    type: "ValidatorNumberField",
    path: "divorce.netIncomePetitioner",
    fieldType: "money",
  },
  {
    type: "ValidatorNumberField",
    path: "divorce.netIncomePartner",
    fieldType: "money",
  },
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsQuestions",
    options: () => trueFalseDefaultOptions("divorce.pensionsQuestions"),
  },
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsExcluded",
    options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
  },
  {
    type: "ValidatorSelect",
    path: "divorce.shortMarriage",
    isDisabled: () => true,
    isHidden: ({ values }: { values: AbstractCase }) => formValue(values, "divorce.pensionsExcluded") === false,
    options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
  },
];

export const stageFormDefinition = {
  [stagesDivorce.STAGE_INCOMPLETE]: incompleteAndReadyData,
  [stagesDivorce.STAGE_READY]: incompleteAndReadyData,
  [stagesDivorce.STAGE_CHECKED]: [...installmentData, ...checkedAndProcessPaidDisabledFields],
  [stagesDivorce.STAGE_PROCESS_PAID]: [...installmentData, ...checkedAndProcessPaidDisabledFields],
  [stagesDivorce.STAGE_SUBMITTED]: submittedFields,
  [stagesDivorce.STAGE_JUDICIAL_PROCESS]: submittedFields,
  [stagesDivorce.STAGE_DATE_SET]: [
    {
      type: "ValidatorTextField",
      path: "divorce.referenceCourt",
    },
    ...installmentData,
    {
      type: "ValidatorSelect",
      path: "divorce.pensionsExcluded",
      options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: "divorce.valueInDisputeReal",
      fieldType: "money",
      numberType: "white",
    },
  ],
  [stagesDivorce.STAGE_HEARING_PAID]: [
    {
      type: "ValidatorTextField",
      path: "divorce.referenceCourt",
    },
    ...installmentData,
    {
      type: "ValidatorSelect",
      path: "divorce.pensionsExcluded",
      options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: "divorce.valueInDisputeReal",
      fieldType: "money",
      numberType: "white",
    },
  ],
  [stagesDivorce.STAGE_DIVORCE_DONE]: [
    {
      type: "ValidatorTextField",
      path: "divorce.referenceCourt",
    },
    ...installmentData,
    {
      type: "ValidatorSelect",
      path: "divorce.pensionsExcluded",
      options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
      isDisabled: () => true,
    },
    {
      type: "ValidatorNumberField",
      path: "divorce.valueInDisputeReal",
      fieldType: "money",
      numberType: "white",
    },
  ],
  [stagesDivorce.STAGE_COMPLETED]: [
    ...installmentData,
    {
      type: "ValidatorNumberField",
      path: "divorce.valueInDisputeReal",
      fieldType: "money",
      numberType: "white",
    },
  ],
};
